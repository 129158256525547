<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-collections-unit-content-wrapper" :class="collection.units.length==1?'k-collections-unit-content-wrapper-single-unit':''">
	<div class="ma-2">
		<div class="d-flex">
			<h3 v-show="unit.title" class="ml-1" style="font-weight:normal; line-height:1.3em;">
				<v-icon class="mr-2" :style="folder_icon_css" style="font-size:20px;margin-top:-3px">fas fa-forward</v-icon>
				<b v-if="unit.display_number" class="mr-2" v-html="unit.display_number"></b>
				<span v-html="title_for_header"></span>
				<span v-if="unit.duration>0" class="ml-2" style="font-size:0.8em;color:#666;font-weight:bold">{{(term_mode=='block')?(unit.duration/2):unit.duration}} WEEK{{unit.duration!=1?'S':''}}</span>
			</h3>
			<v-spacer/>
			<div style="width:220px" class="k-instance-unit-autocomplete" v-if="show_instance_selector"><v-autocomplete ref="instance_selector" dense outlined class="ml-4"
                :items="instance_selector_options" item-text="title" v-model="instance_selector_value" label="Show content for…"
                @change="select_instance_unit" return-object>
				<template v-slot:item="{ item }"><span style="font-size:14px">
					<span v-if="item.is_flex_unit"><b>Template Content:</b> {{item.title}}</span>
					<span v-else>{{item.title}}</span>
				</span></template>
            </v-autocomplete></div>
			<!-- <div v-if="viewing_users_shadow_unit&&site_config.enable_sandbox_simulate_role_menu=='yes'"> -->
			<div v-if="false">
				<v-spacer/>
				<!-- we may not end up supporting this: for districts we have the coteacher role which is similar; for states people can just make my collections -->
				<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" small  class="k-edit-btn xk-tight-btn xk-nocaps-btn" color="#555" dark @click=""><v-icon class="mr-1" small>fas fa-user</v-icon>View Content For…</v-btn></template>
					<v-list dense min-width="250">
						<v-list-item @click="share_my_content"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Share My Content for this collection with another user…</v-list-item-title></v-list-item>
						<v-list-item @click="subscribe_to_my_content"><v-list-item-icon><v-icon small>fas fa-cubes-stacked</v-icon></v-list-item-icon><v-list-item-title>Subscribe to another user’s My Content for this collection…</v-list-item-title></v-list-item>
						<!-- <v-divider/> -->
					</v-list>
				</v-menu>
			</div>
		</div>

		<div v-if="unit_text" class="k-collection-unit-description-outer" v-visible="unit_description_style!='visibility:hidden'">
			<div v-if="!unit_descriptions_collapsed" class="k-collection-unit-description fr-view px-3 pt-2 pb-0 white" :style="(true||unit_show_full_description_btn)?'border-radius:10px 10px 0 0;border-bottom:1px solid #ccc;':'border-radius:10px;'">
				<div class="k-collection-unit-description-inner pb-2" :style="unit_description_style" v-html="U.render_latex(unit_text)"></div>
				<div style="clear:both;padding-bottom:1px;"></div><!-- this prevents annoying extra margin at the bottom of the description div -->
			</div>
			<div class="d-flex" style="cursor:pointer;border-radius:0 0 8px 8px;" :style="unit_descriptions_collapsed?'':'border-top:1px solid #ccc'">
				<v-btn v-if="!unit_descriptions_collapsed&&unit_show_full_description_btn" small text color="secondary" style="border-radius:0 0 0 8px" @click="unit.full_description_showing=!unit.full_description_showing">Show {{unit.full_description_showing?'Less':'More'}}<v-icon small class="ml-2">fas fa-caret-{{unit.full_description_showing?'up':'down'}}</v-icon></v-btn>
				<v-spacer/>
				<v-btn v-if="!unit_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="toggle_unit_descriptions_collapsed">Collapse Unit Descriptions<v-icon small class="ml-2">fas fa-xmark</v-icon></v-btn>
				<v-btn v-if="unit_descriptions_collapsed" small text color="secondary" class="k-tight-btn" style="border-radius:0 0 8px 0" @click="toggle_unit_descriptions_collapsed">Show Unit Descriptions<v-icon small class="ml-2">fas fa-caret-down</v-icon></v-btn>
			</div>
		</div>
	</div>

	<div class="d-flex mt-4" v-if="show_unit_mode_toggle">
		<v-spacer/>
		<v-btn-toggle class="k-course-view-toggle-btn elevation-3" active-class="k-course-view-unit-toggle-btn-active-class" v-model="unit_mode" mandatory>
			<v-btn v-if="show_resources_tab" style="min-width:136px" small class="k-tight-btn k-nocaps-btn" :value="'resources'" @click="unit_mode_changed('resources')"><v-icon small class="mr-1" :color="(unit_mode == 'resources') ? 'primary' : '#888'">fas fa-box-archive</v-icon>{{ resources_button_text}} ({{ base_unit_resource_count }})</v-btn>
			<v-btn v-if="show_standards_tab" style="min-width:136px" small class="k-tight-btn k-nocaps-btn" :value="'standards'" @click="unit_mode_changed('standards')"><v-icon small class="mr-1" :color="(unit_mode == 'standards') ? 'primary' : '#888'">fas fa-bullseye</v-icon>{{ standards_button_text}} ({{ standards_count }})</v-btn>
			<v-btn v-if="show_my_content_tab" style="min-width:136px" small class="k-tight-btn k-nocaps-btn" :value="'my_content'" @click="unit_mode_changed('my_content')"><v-icon small class="mr-1" :color="(unit_mode == 'my_content') ? 'primary' : '#888'">fas fa-cubes-stacked</v-icon>{{ my_content_button_text }} ({{ shadow_unit_resource_count }})</v-btn>
			<v-btn v-if="show_assignments_tab" style="min-width:176px" small class="k-tight-btn k-nocaps-btn" :value="'assignments'" @click="unit_mode_changed('assignments')"><v-icon small class="mr-1" :color="(unit_mode == 'assignments') ? 'primary' : '#888'">fas fa-bolt</v-icon>{{assignment_center_button_text}} ({{ shadow_unit_resource_count }})</v-btn>
			<v-btn v-if="show_messages_tab" style="min-width:176px" small class="k-tight-btn k-nocaps-btn" :value="'messages'" @click="unit_mode_changed('messages')"><v-icon small class="mr-1" :color="(unit_mode == 'messages') ? 'primary' : '#888'">fas fa-comments</v-icon>{{ messages_button_text }}</v-btn>
		</v-btn-toggle>
		<v-spacer/>
	</div>

	<div v-if="unit_mode == 'resources' || unit_mode == 'my_content' || (!show_assignments && unit.standards.length == 0)">
		<div style="display:flex" class="justify-center mx-2">
			<div style="flex:1 1 100%">
				<div class="d-flex align-center mt-3 mr-1 mb-2" v-if="unit_has_resources">
					<div class="k-unit-view-header" v-html="resources_header_text"></div>
					<div v-if="show_sort_by_control" class="d-flex align-center" style="margin-top:-8px">
						<div style="font-size:16px" class="mr-2">Sort by:</div>
						<div>
							<v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="default_collection_sort_by" mandatory>
								<v-btn x-small width="90px" light :value="'title'" @click.stop="previous_default_collection_sort_by='title'">Title</v-btn>
								<v-btn x-small width="90px" light :value="'created_at'" class="k-tight-btn" @click.stop="set_created_at_sort_order">Date Created</v-btn>
							</v-btn-toggle>
						</div>
					</div>
					<v-spacer/>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="at_least_one_folder_closed" v-on="on" x-small fab outlined color="#555" class="elevation-0 mr-2" @click="open_all_folders"><v-icon small>fas fa-angles-down</v-icon></v-btn></template>Open all folders</v-tooltip>
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="at_least_one_folder_open" v-on="on" x-small fab outlined color="#555" class="elevation-0 mr-2" @click="close_all_folders"><v-icon small>fas fa-angles-up</v-icon></v-btn></template>Close all folders</v-tooltip>
					<v-btn-toggle v-if="collection.collection_type !== 'pd'" dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="collection_view_mode" mandatory>
						<v-btn x-small light :value="'tiles'" @click.stop="">Tile View</v-btn>
						<v-btn x-small light :value="'list'" @click.stop="">List View</v-btn>
					</v-btn-toggle>
				</div>
				<div v-if="!unit_has_resources" class="mt-5 text-center">
					<div v-if="unit_mode=='my_content'"><i>You have not created or copied any resources for this unit.</i></div>
					<div v-else><i>This {{collection.units.length>1?'unit':'collection'}} does not include any resources.</i></div>
				</div>
				<div class="k-collection-unit-wrapper-outer">
					<div class="k-collection-unit-wrapper">
						<div v-if="search_results_units&&!search_results_units.includes(unit)" class="text-center mt-4 pa-2 yellow lighten-3" style="border-radius:8px;"><i>No resources in this unit match your search terms.</i></div>
						<div class="k-collection-unit-resources mb-6">
							<CollectionResourceFolder ref="top_collection_resource_folder"
								:collection="collection" 
								:unit="content_unit" 
								:search_results_items="search_results_items" 
								:folder_id="'top'" 
								:enable_collection_editing="enable_collection_editing"
								:term_mode="term_mode"
								@clear_collection_last_search_results="$emit('clear_collection_last_search_results')" 
								@save_unit_edits="save_unit_edits"
								@copy_to_shadow_unit_finish="copy_to_shadow_unit_finish"
								@resource_search_closed="cancel_unit_editing"
								@lesson_shift_update="$emit('lesson_shift_update',$event)"
								@create_activity_from_resource="create_activity_from_resource"
							/>
						</div>
					</div>
				</div>
				<div v-if="false&&!is_collection_admin" class="mb-4 text-center"><v-btn outlined class="k-tight-btn k-nocaps-btn" color="primary" @click=""><v-icon small class="mr-2">fas fa-bolt</v-icon>Recommend a new Lesson, Student Activity or other Resource<v-icon small class="ml-2">fas fa-bolt</v-icon></v-btn></div>
				<div class="text-center">
					<v-btn small v-if="show_add_content_btn" class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="add_content"><v-icon class="mr-1" small>fas fa-plus</v-icon>Add Content</v-btn>
					<v-btn small v-if="user_can_edit_unit" class="k-edit-btn xk-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="edit_unit"><v-icon class="mr-2" small>fas fa-edit</v-icon>Edit Unit</v-btn>
					<v-btn small v-if="can_view_pd_report" class="k-edit-btn k-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="show_todo_report=true"><v-icon class="mr-2" small>fas fa-list-check</v-icon>Show Resource Completion Report</v-btn>
					<v-btn small v-if="is_collection_admin&&collection.units.length==1" class="k-edit-btn k-tight-btn xk-nocaps-btn mx-1 mb-3 elevation-0" color="#ddd" @click="$emit('add_unit')"><v-icon small class="mr-2">fas fa-plus</v-icon>Add a new Unit to this Collection</v-btn>
					<!-- <v-btn small color="deep-orange darken-3 k-tight-btn elevation-0" dark class="mx-1 mb-3" @click="open_vlc"><v-icon small class="mr-2">fas fa-check</v-icon> Velocity Learning Check</v-btn> -->
				</div>

			</div>
		</div>
	</div>

	<!-- Standards -->
	<div class="" v-if="unit_mode == 'standards' && unit.standards.length > 0">
		<CollectionUnitStandards :lp="collection" :unit="unit" :is_collection_admin="is_collection_admin" 
			:search_results_units="search_results_units" :search_results_items="search_results_items"
			@edit_unit="edit_unit" 
			@copy_to_shadow_unit_finish="copy_to_shadow_unit_finish"
		/>
	</div>

	<!-- Assignment	Center -->
	<div class="" v-if="show_assignments">
		<DirectivesWrapper ref="directives" :lp="collection" :unit="content_unit" :filtered_origin_teacher_id="filtered_origin_teacher_id" />
	</div>

	<!-- Message Center -->
	<div class="" v-if="show_messages">
		<MessagesView :course_code="collection.course_code" />
	</div>

	<CollectionUnitEdit v-if="show_unit_editor" :collection="collection" :original_unit="unit"
			:instance_units="available_instance_units" @editor_cancel="cancel_unit_editing" @editor_refresh="editor_refresh"
			@get_instance_units="$emit('get_instance_units')" />
	<TodoReportCollectionUnit v-if="show_todo_report" :collection="collection" :unit="unit" @dialog_cancel="show_todo_report=false" />
</div></template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CollectionUnitStandards from './CollectionUnitStandards'
import CollectionResourceFolder from './CollectionResourceFolder'
import DirectivesWrapper from '../directives/DirectivesWrapper'
import CollectionUnitEdit from './CollectionUnitEdit'
import TodoReportCollectionUnit from '../reports/TodoReportCollectionUnit'
import MessagesView from '../messages/MessagesView'

export default {
	components: { CollectionUnitStandards, CollectionResourceFolder, CollectionUnitEdit, TodoReportCollectionUnit, DirectivesWrapper, MessagesView },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		is_collection_admin: { type: Boolean, required: true },
		unit_descriptions_collapsed: { type: Boolean, required: false, default() { return false }},
		unit_show_full_description_btn: { type: Boolean, required: true },
		unit_description_style: { type: String, required: true },
		search_results_items: { type: Array, required: false, default() { return [] }},
		search_results_units: { required: false, default() { return null }},
		term_mode: { required: false, default() { return 'normal' }},
		instance_units: { required: true, type: Object },
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
		is_collection_unit_component: true,	// used by CollectionResourceFolders to find this component
		show_todo_report: false,
		show_unit_editor: false,
		shadow_unit: null,
		unit_mode: '',
		instance_selector_value: '',
	}},
	computed: {
		...mapState(['user_info', 'lp_edit_locked', 'my_lessons', 'my_resources', 'my_ca_mappings', 'site_config', 'lst']),
		...mapGetters(['studentish_role', 'my_default_collection', 'manage_assignments', 'use_message_center', 'show_all_items_when_not_signed_in', 'signed_in', 'unread_message_count', 'immediately_show_standards_aligned_resources']),
		unit_text() {
			if (empty(this.unit.text)) return ''

			// annoying: text could have nothing but a "powered-by-froala" block; pull this out
			let div = $(`<div>${this.unit.text}</div>`)
			div.find('[data-f-id=pbf]').remove()
			return div.html()
		},
		is_flex_unit() {
			return this.unit?.is_flex_unit ?? false
		},
		is_instance_unit() {
			return this.unit?.instance_unit_of !== 0 ?? false
		},
		flex_unit_id() {
			if (!this.is_flex_unit && !this.is_instance_unit) return 0
			return this.is_flex_unit ? this.unit.lp_unit_id : this.unit.instance_unit_of
		},
		available_instance_units() {
			// The instance_units object prop includes all instance units for the collection, keyed to flex unit IDs
			// For convenience, we limit it here to just the instance unit children of the current flex unit
			if (!this.flex_unit_id || this.flex_unit_id === 0) return []
			return [...this.instance_units[`${this.flex_unit_id}`] ?? []]
		},
		show_instance_selector() {
			// If this is a flex unit or instance unit AND there are multiple instance units available for this flex unit,
			//  we render the unit title in a drop-down that allows the user to select which unit to view
			return ((this.is_flex_unit || this.is_instance_unit) && this.available_instance_units.length > 0)
		},
		instance_selector_options() {
			//  If we are currently showing an instance unit, we want to include the parent Flex Unit in the dropdown for instance units
			if (this.is_instance_unit) {
				const flex_unit = this.collection.units.find(u => u.lp_unit_id == this.unit.instance_unit_of)
				if (flex_unit) return [flex_unit, this.unit, ...this.available_instance_units]
			}
			// Otherwise, this.unit == flex unit
			return [this.unit, ...this.available_instance_units]
		},
		content_unit() {
			// if this isn't an agency_sanctioned unit, or this isn't a teacher-level user, or unit_mode isn't 'my_content', show the "base" unit content
			if (!this.is_agency_sanctioned_collection) return this.unit
			if (this.studentish_role) return this.unit
			if (this.unit_mode != 'my_content' && this.unit_mode != 'assignments') return this.unit
			
			// if we get to here this is an agency_sanctioned lp being viewed by a teacher and unit_mode is 'my_content' or 'assignment', so show the teacher's "shadow unit", which we (currently) create locally, on the fly, below
			console.log('----- RETURNING SHADOW UNIT')
			return this.shadow_unit
		},
		title_for_header() {
			if (this.is_instance_unit) {
				const flex_unit = this.collection.units.find(u => u.lp_unit_id == this.unit.instance_unit_of)
				return `<b style="font-size:0.8em">${flex_unit.title}:</b> ${this.unit.title}`
			} else {
				return this.unit.title
			}
		},
		is_default_collection() { return (this.collection.course_code == 'default') },
		is_agency_sanctioned_collection() { return this.collection.agency_sanctioned },
		show_resources_tab() { 
			// always show resources tab for all collection types other than 'course'
			if (this.collection.collection_type != 'course') return true

			// if we're not showing the standards tab (because there are no standards specified), always show the resources tab
			if (!this.show_standards_tab) return true

			// if we get to here, show the resources tab if we have content tied to the collection itself (not counting a user's my resources)
			if (this.unit?.resources.length > 0) return true
			if (this.unit?.lessons.length > 0) return true
			
			return false
		},
		show_standards_tab() { return this.unit.standards.length > 0 },
		show_assignments_tab() { return this.collection.collection_type == 'course' && this.shadow_unit != null && this.manage_assignments },
		show_my_content_tab() { 
			if (this.shadow_unit == null) return false

			if (this.is_flex_unit || this.is_instance_unit) return false

			// for courses, 
			if (this.collection.collection_type == 'course') {
				// show the my content tab if we're not managing assignments (you can't be both managing assignments and using my content for the same collection/unit at the same time, because they use the same data to determine what is "my content" and what is a directive)
				return !this.manage_assignments
			} else {
				// else you can have "my content" for repos (but not "pd" or "my" collections)
				return this.collection.collection_type == 'repo'
			}
		},
		show_messages_tab() { return this.collection.collection_type == 'course' && this.use_message_center && this.signed_in },
		show_assignments() { return this.show_assignments_tab && this.unit_mode === 'assignments' },
		show_messages() { return this.show_messages_tab && this.unit_mode === 'messages' },
		show_unit_mode_toggle() {
			// show the toggle button if we're showing at least two buttons...
			let n_btns = 0
			if (this.show_resources_tab) n_btns += 1
			if (this.show_standards_tab) n_btns += 1
			if (this.show_my_content_tab) n_btns += 1
			if (this.show_assignments_tab) n_btns += 1
			if (this.show_messages_tab) n_btns += 1
			return n_btns > 1
		},

		// Customized Unit Toggle button titles from config
		resources_button_text() { return this.site_config.unit_mode_toggle_option?.resources || 'Resources' },
		standards_button_text() { return this.site_config.unit_mode_toggle_option?.standards || 'Standards' },
		my_content_button_text() { return this.site_config.unit_mode_toggle_option?.my_content || 'My Content' },
		assignment_center_button_text() {
			if (!this.manage_assignments) return ''
			const button_text = this.site_config.unit_mode_toggle_option?.assignments || 'Activities'
			if (this.role == 'parent') return button_text	// My Child’s
			else if (this.role == 'student') return button_text
			else return `My Lessons & ${button_text}`
		},
		messages_button_text() {
			const button_text = this.site_config.unit_mode_toggle_option?.messages || 'Message Center'
			const n = this.unread_message_count(this.collection.course_code)
			return `${button_text}${n ? ` (${n})` : ''}`
		},

		viewing_shadow_unit() { return (this.unit_mode == 'my_content' && this.show_my_content_tab) },
		viewing_users_shadow_unit() { return (this.viewing_shadow_unit && this.shadow_unit.shadow_unit_owner_id == this.user_info.user_id) },
		show_sort_by_control() {
			if (this.is_default_collection) return true
			if (this.unit_mode == 'my_content') return true
			return false
		},
		user_is_instance_unit_admin() {
			if (this.is_collection_admin) return true
			return this.user_info.admin_rights.includes(`admin_instance.unit.${this.unit.lp_unit_id}`)
		},
		user_is_instance_unit_editor() {
			if (this.is_collection_admin || this.user_is_instance_unit_admin) return true
			return this.user_info.admin_rights.includes(`edit_instance.unit.${this.unit.lp_unit_id}`)
		},
		show_add_content_btn() {
			if (this.is_instance_unit) return this.user_is_instance_unit_editor

			// if you're viewing your shadow unit or in default collection, you can add content (we don't have folders in the shadow unit)
			if (this.viewing_users_shadow_unit || this.is_default_collection) return true

			// otherwise unless your a collection admin, you surely won't be able to add content
			if (!this.is_collection_admin) return false

			// now, if the unit doesn't have any folders, allow to add content directly to the unit (if there there is only 1 folder, it is the 'top' folder, which isn't a folder the user added)
			if (this.unit.resource_tree.folders.length <= 1) return true
			return false
		},
		user_can_edit_unit() {
			if (this.is_instance_unit) {
				if (this.is_collection_admin) return true
				const unit_id = this.unit.lp_unit_id
				const user_is_editor = this.user_info.admin_rights.includes(`admin_instance.unit.${unit_id}`) || this.user_info.admin_rights.includes(`edit_instance.unit.${unit_id}`)
				return user_is_editor
			} else {
				return (this.is_collection_admin && !this.is_default_collection && this.unit_mode != 'my_content')
			}
		},
		// Get the course guidance items from unit 1 in an object
		course_guidance_items() {
			// for the 'Resources for Course Guidance' folder in HenryConnects, we want to include items from other course_guidance folders in the same collection...
			// here we get the list of extra items from other units; we then use this to accurately reflect the count of resources, and show the extra resources in the course guidance folder
			/** 
			 * { resource_for_course_guidance_folder: {
			 * 		items: [item, item, item,...]
			 * 		parent_folder_id: 'top'
			 * 		title: folder_title,
			 * 		count: count of all resources in folder(s)
			 * 		...
			 * 	 },
			 *   {subfolder_id}: {
			 * 		items: [item, item, item,...]
			 * 		parent_folder_id: {parent_folder_id}
			 * 		title: folder_title
			 * 		count: count of all resources in folder(s)
			 * 		...
			 * 	 }
			 * } 
			 */
			let obj = {}
			const resources_in_unit_1 = this.collection.units[0].resources.reduce((a, v) => { a[v.resource_id] = v; return a; }, {})
			const lessons_in_unit_1 = this.collection.units[0].lessons.reduce((a, v) => { a[v.lesson_id] = v; return a; }, {})
			const this_unit_course_guidance_folder = this.unit.resource_tree.folders.find(x => x.folder_id.startsWith('course_guidance'))
			const get_course_guidance_items = (root) => {
				if (!root) return 0
				// Create the object
				const key = root.folder_id.startsWith('course_guidance') ? 'course_guidance' : root.folder_id
				if (!obj[key]) obj[key] = {parent_folder_id: root.parent_folder_id, items: [], title: root.title, count: 0}
				for (const child_id of root.children) {
					if (child_id in resources_in_unit_1) {
						obj[key].items.push(resources_in_unit_1[child_id])
					}
					if (child_id in lessons_in_unit_1) {
						obj[key].items.push(lessons_in_unit_1[child_id])
					}
					obj[key].count += 1
				}
				// Create the new folders for the unit
				if (root.parent_folder_id !== 'top' && !this.unit.resource_tree.folders.find(x => x.folder_id === root.folder_id)) {
					if (root.parent_folder_id.startsWith('course_guidance')) {
						this.unit.create_resource_folder({parent_folder_id: this_unit_course_guidance_folder.folder_id, folder_id: root.folder_id, title: root.title, seq: root.seq, color: root.color })
					} else {
						this.unit.create_resource_folder({parent_folder_id: root.parent_folder_id, folder_id: root.folder_id, title: root.title, seq: root.seq, color: root.color })
					}
				}
				for (const folder of this.collection.units[0].resource_tree.folders) {
					if (folder.parent_folder_id === root.folder_id) {
						obj[key].count += get_course_guidance_items(folder)
					}
				}
				return obj[key].count
			}
			const root = this.collection.units[0].resource_tree.folders.find(folder => folder.folder_id.startsWith('course_guidance') && folder.children.length > 0)
			if (root) get_course_guidance_items(root)

			return obj
		},
		base_unit_resource_count() {
			// if we have search results, only count items that match search
			if (this.search_results_items.length > 0) {
				let n = 0
				for (let sri of this.search_results_items) {
					let item = (sri.type == 'lesson') ? this.unit.lessons.find(x=>x.lesson_id==sri.item_id) : this.unit.resources.find(x=>x.resource_id==sri.item_id)
					if (!item) continue
					// if (!item.is_visible_by_user()) continue
					++n
				}
				return n
			} else {
				// lessons that are loaded are viewable if not restricted or this isn't a student (studentish people may also not get some lessons loaded)
				let n = 0
				for (let lesson of this.unit.lessons) {
					if (lesson.restricted && this.studentish_role) continue
					++n
				}

				// for resources we have to do some other checks, as in CollectionResourceFolder
				for (let resource of this.unit.resources) {
					// hide/show items marked as block or traditional; note that 'traditional' resource == 'normal' term_mode; but don't hide when editing
					if (!this.enable_collection_editing) {
						if (resource.block_or_traditional == 'block' && this.term_mode != 'block') continue
						if (resource.block_or_traditional == 'traditional' && this.term_mode != 'normal') continue
					}

					if (!this.show_all_items_when_not_signed_in && this.studentish_role && this.collection.collection_type == 'course') {
						// the user can't see the item unless it's explicicly marked as family_avail, or it's part of a tcc collection
						if (!resource.family_avail && !resource.tcc_folder_id) continue
					}

					// items marked as leader_resource can only be viewed by admins and principals/assistant principals
					if (resource.lp_category == 'leader_resource') {
						if (!(this.$store.getters.user_is_principal_or_ap || this.is_collection_admin)) {
							continue
						}
					}

					++n
				}

				// check for special course_guidance resources
				if (this.unit.lp_unit_id !== this.collection.units[0].lp_unit_id && this.course_guidance_items['course_guidance']) n += this.course_guidance_items['course_guidance'].count
				return n
			}
		},
		shadow_unit_resource_count() {
			if (!this.shadow_unit) return 0
			// if we have search results, only count items that match search
			if (this.search_results_items.length > 0) {
				let n = 0
				for (let item of this.search_results_items) {
					if (this.shadow_unit.lessons.find(x=>x.lesson_id&&x.lesson_id==item.item_id) || this.shadow_unit.resources.find(x=>x.resource_id&&x.resource_id==item.item_id)) ++n
				}
				return n
			}
			// note: the user can definitionally see all items in their shadow unit	
			return this.shadow_unit.lessons.length + this.shadow_unit.resources.length
		},
		standards_count() {
			if (this.unit.standards.length == 0) return 0

			// if this site_config setting is 'yes', show a count of all assets aligned to the unit (ALEX, where the header for this tab is "Content By Standard")
			if (this.site_config.show_resource_count_in_unit_standards_tag == 'yes') {
				let s = new Set()
				for (let x of this.unit.standards_aligned_assets) {
					// if we have search results only count matching assets
					if (this.search_results_items.length > 0) {
						if (!this.search_results_items.find(y=>y.item_id == x.asset_id)) continue
					}
					// console.warn('standards_count', x.asset_id)
					s.add(x.asset_id)
				}

				return s.size

			} else {
				// else return a count of the number of unit standards
				return this.unit.standards.length
			}
		},
		enable_collection_editing() {
			// for most collections, the user has to click the edit button to edit the unit
			// but for the default collection, and shadow units, we don't allow for the edit button, so show editing functionality (e.g. remove) from here
			return this.is_default_collection || this.viewing_users_shadow_unit
		},
		collection_view_mode: {
			get() {
				if (this.collection.collection_type === 'pd') return 'list'
				return this.$store.state.lst.collection_view_mode
			},
			set(val) { this.$store.commit('lst_set', ['collection_view_mode', val]) }
		},
		default_collection_sort_by: {
			get() { return this.$store.state.lst.default_collection_sort_by },
			set(val) { this.$store.commit('lst_set', ['default_collection_sort_by', val]) }
		},
		unit_has_resources() {
			if (!this.content_unit) return false
			if (!this.signed_in && this.base_unit_resource_count == 0) return false

			// check for special course_guidance resources
			if (this.course_guidance_items['course_guidance']?.count > 0) return true

			if (this.content_unit.resources.length > 0) return true
			if (this.content_unit.lessons.length > 0) return true
			// PW 10/18/2024: this line was in place before, but it's non-functional: it should be checking folders.length. But I'm not sure if we really want this to return true if folders.length > 0
			// if (this.content_unit.resource_tree.folders > 0) return true
			return false
		},
		can_view_pd_report() {
			// only applicable for PD collections
			if (this.collection.collection_type != 'pd') return false
			return vapp.has_admin_right('pd_rep.any')
		},
		at_least_one_folder_closed() {
			let cof = this.$store.state.lst.collections_opened_folders
			let folder_closed = (folder_id) => {
				if (folder_id != 'top' && !cof[folder_id]) return true

				for (let f of this.content_unit.resource_tree.folders) {
					if (f.parent_folder_id == folder_id) {
						if (folder_closed(f.folder_id)) return true
					}
				}
				return false
			}
			return folder_closed('top')
		},
		at_least_one_folder_open() {
			let cof = this.$store.state.lst.collections_opened_folders
			let folder_open = (folder_id) => {
				if (folder_id != 'top' && cof[folder_id] == 'open') return true

				for (let f of this.content_unit.resource_tree.folders) {
					if (f.parent_folder_id == folder_id) {
						if (folder_open(f.folder_id)) return true
					}
				}
				return false
			}
			return folder_open('top')
		},
		folder_icon_css() {
			// return U.subject_tile_css(this.collection) + '-text'
			return U.collection_color_style(this.collection, 'text')
		},
		resources_header_text() {
			if (this.is_default_collection || this.unit_mode == 'my_content') {
				// for the default collection, the units are named after the types;
				// and for "my content", the folders are named after the types
				return ''
			} else {
				return this.site_config.unit_mode_toggle_option?.resources || 'Resources'
			}
		},
	},
	watch: {
		my_lessons: { deep:true, handler() { this.create_shadow_unit() }},
		my_resources: { deep:true, handler() { this.create_shadow_unit() }},
		my_ca_mappings: { deep:true, handler() { this.create_shadow_unit() }},
		'unit.lp_unit_id': { deep:false, handler() { this.create_shadow_unit() }},
	},
	created() {
		this.create_shadow_unit()
		vapp.collection_unit_component = this

		// set initial value for unit_mode
		if (!this.show_unit_mode_toggle) {
			// in the case where we're not even showing the unit mode toggle (e.g. my and pd collections), unit_mode has to be the one mode we're showing
			if (this.show_resources_tab) this.unit_mode = 'resources'
			if (this.show_standards_tab) this.unit_mode = 'standards'
			if (this.show_my_content_tab) this.unit_mode = 'my_content'
			if (this.show_assignments_tab) this.unit_mode = 'assignments'
			if (this.show_messages_tab) this.unit_mode = 'messages'

		} else {
			// start with the value from lst, but then correct if necessary based on the circumstances
			this.unit_mode = this.$store.state.lst.unit_mode
			if (!this.show_standards_tab && this.unit_mode == 'standards') this.unit_mode = 'resources'
			else if (!this.show_resources_tab && this.unit_mode == 'resources') this.unit_mode = 'standards'
			else if ((!this.show_my_content_tab && this.unit_mode == 'my_content')
				|| (!this.show_assignments && this.unit_mode == 'assignments')
				|| (!this.show_messages_tab && this.unit_mode == 'messages')) {
					// we should always be showing either the resources tab or the standards tab
					if (this.show_resources_tab) this.unit_mode = 'resources'
					else this.unit_mode = 'standards'
				}
		}
		// if the user is viewing the toggle, and clicks to change the toggle, we will update lst in unit_mode_changed below
	},
	mounted() {
		this.previous_default_collection_sort_by = this.default_collection_sort_by
	},
	methods: {
		unit_mode_changed(val) {
			this.$store.commit('lst_set', ['unit_mode', val])
		},

		select_instance_unit(instance_unit) {
			// if user hits backspace to clear the autocomplete value, select the flex unit
			if (empty(instance_unit)) instance_unit = this.flex_unit

			// add new default instance unit to LST, keyed to the flex unit id
			const o = this.lst.default_instance_unit
			const flex_unit_id = this.is_instance_unit ? this.unit.instance_unit_of : this.unit.lp_unit_id
			o[`${flex_unit_id}`] = instance_unit.lp_unit_id
			this.$store.commit('lst_set', ['default_instance_unit', o])

			// Change view to show selected instance unit
			this.$emit('toggle_unit', instance_unit)
			// this.$emit('toggle_unit', new LP_Unit(instance_unit))

			// remove focus from the autocomplete
			this.$refs.instance_selector.blur()
			// clear this.instance_selector_value after a tick; that clears the autocomplete
			setTimeout(x=>this.instance_selector_value = '', 0)
		},

		// this is where we, on the fly, create the "shadow" unit for the user, which includes items that they've copied from this unit, or items that they've created in this unit
		create_shadow_unit() {
			// parents/students currently can't do anything with resource copies, so no need for a shadow unit for them
			if (this.studentish_role) return

			// no need for shadow units in the default collection
			if (this.is_default_collection) return

			// no need, at least currently, for shadow units in other non-agency_sanctioned collections either
			if (!this.collection.agency_sanctioned) return

			// if we are in a flex or instance unit, we can eject
			if (this.is_flex_unit || this.is_instance_unit) return

			// console.warn('CollectionUnit: create_shadow_unit: ' + this.unit.lp_unit_id)

			// for non-studentish users, we always create the shadow unit for collections: we only show the "My Content" tab for agency-sanctioned collections, but for non-agency-sanctioned collections, we use the shadow unit to manage copies of the resources for students

			// create the shadow unit with 'my' lessons and resources tagged to this lp_unit_id
			let lessons = []
			for (let lesson of this.unit.lessons) {
				// note that we look both in my_xxx and in the default collection
				let copy = this.my_lessons.find(x=>x.lesson_template_id == lesson.lesson_id)
				if (!copy) copy = this.my_default_collection.units[0].lessons.find(x=>x.lesson_template_id == lesson.lesson_id)
				if (copy) lessons.push(copy)
			}
			let sparkl_activities = []
			let link_resources = []
			for (let resource of this.unit.resources) {
				// note that we look both in my_xxx and in the default collection
				let copy = this.my_resources.find(x=>x.resource_template_id == resource.resource_id)
				if (!copy) {
					if (resource.type == 'sparkl') copy = this.my_default_collection.units[1].resources.find(x=>x.resource_template_id == resource.resource_id)
					else copy = this.my_default_collection.units[2].resources.find(x=>x.resource_template_id == resource.resource_id)
				}
				if (copy) {
					if (resource.type == 'sparkl') sparkl_activities.push(copy)
					else link_resources.push(copy)
				}
			}

			// add items created by the user and expressly tagged to the unit
			for (let ca_mapping of this.my_ca_mappings) {
				if (ca_mapping.collection_id == this.collection.lp_id && ca_mapping.unit_id == this.unit.lp_unit_id) {
					// console.log(object_copy(ca_mapping))
					if (ca_mapping.asset_type == 'lesson') {
						let item = this.my_lessons.find(x=>x.lesson_id == ca_mapping.asset_id)
						if (item && !lessons.find(x=>x.lesson_id == item.lesson_id)) lessons.push(item)
					} else {
						let item = this.my_resources.find(x=>x.resource_id == ca_mapping.asset_id)
						if (item) {
							if (item.type == 'sparkl' && !sparkl_activities.find(x=>x.resource_id == item.resource_id)) sparkl_activities.push(item)
							if (item.type != 'sparkl' && !link_resources.find(x=>x.resource_id == item.resource_id)) link_resources.push(item)
						}
					}
				}
			}

			// sort in alpha order; CollectionResourceFolder will change to date if necessary
			lessons.sort((a,b)=>U.natural_sort(a.lesson_title, b.lesson_title))
			sparkl_activities.sort((a,b)=>U.natural_sort(a.description, b.description))
			link_resources.sort((a,b)=>U.natural_sort(a.description, b.description))

			// create the shadow_unit, then add folders for lessons and resources
			let unit = new LP_Unit({
				shadows_lp_id: this.collection.lp_id,
				shadows_lp_unit_id: this.unit.lp_unit_id,
				shadow_unit_owner_id: this.user_info.user_id,
				lessons: lessons,
				resources: sparkl_activities.concat(link_resources),
				title: this.unit.title,
			})
			// by giving the units consistent folder_ids, we'll remember what's open in lst
			if (lessons.length > 0) unit.create_resource_folder({parent_folder_id:'top', title:'My Lessons', items: lessons, color:'blue', folder_id:`shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-lessons`})
			if (sparkl_activities.length > 0) unit.create_resource_folder({parent_folder_id:'top', title:`My ${this.site_config.sparkl_app_name} Student Activities`, items: sparkl_activities, color:'purple', folder_id:`shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-sparkl_activities`})
			if (link_resources.length > 0) unit.create_resource_folder({parent_folder_id:'top', title:'My Other Resources', items: link_resources, color:'orange', folder_id:`shadow-folder-${this.unit.lp_unit_id}-${this.user_info.user_id}-link_resources`})

			this.shadow_unit = unit
		},

		toggle_unit_descriptions_collapsed() {
			this.$store.commit('lst_set', ['unit_descriptions_collapsed', !this.unit_descriptions_collapsed])
		},

		set_created_at_sort_order(val) {
			if (this.previous_default_collection_sort_by == 'created_at') {
				if (this.$store.state.lst.default_collection_sort_by_created_at_order == 'desc') this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'asc'])
				else this.$store.commit('lst_set', ['default_collection_sort_by_created_at_order', 'desc'])
			}
			this.previous_default_collection_sort_by = 'created_at'
			// console.log(`this.previous_default_collection_sort_by: ${this.previous_default_collection_sort_by}`)
		},

		narrow_window() {
			return $(window).width() < 1000
		},

		open_all_folders() {
			let cof = extobj(this.$store.state.lst.collections_opened_folders)
			for (let f of this.content_unit.resource_tree.folders) cof[f.folder_id] = 'open'
			this.$store.commit('lst_set', ['collections_opened_folders', cof]) 
		},

		close_all_folders() {
			let cof = extobj(this.$store.state.lst.collections_opened_folders)
			for (let f of this.content_unit.resource_tree.folders) delete cof[f.folder_id]
			this.$store.commit('lst_set', ['collections_opened_folders', cof]) 
		},

		edit_unit() {
			// we have to request to edit the unit, to make sure no one else already has the lp opened for editing -- but not for the default collection
			if (this.is_default_collection) {
				this.show_unit_editor = true
				return
			}

			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
				console.log('edit request: ' + result.status)
				this.show_unit_editor = true

			}).catch((e)=>{
				// if this doesn't work, don't enter edit mode
				console.log('error requesting editor checkout for collection unit', e)
			})
		},

		cancel_unit_editing() {
			// when editing is canceled, clear the edit lock. Note that we do this both when the unit editor is closed and when the ResourceSearch dialog is closed
			if (!this.is_default_collection && !this.viewing_shadow_unit) this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
			this.$refs.top_collection_resource_folder?.import_tcc_items()
			this.show_unit_editor = false
		},

		editor_refresh() {
			if (this.is_flex_unit) this.$emit('get_instance_units')
			this.cancel_unit_editing()
			this.$nextTick(() => this.edit_unit())
		},

		add_content() {
			// if we're viewing the default collection, or a user's shadow unit, just start the process
			if (this.is_default_collection || this.viewing_users_shadow_unit) {
				this.$refs.top_collection_resource_folder.search_start()
				return
			}
			
			// adding content is the same as editing the unit, so we have to request to edit the unit, to make sure no one else already has the lp opened for editing
			this.$store.dispatch('edit_access_control', {lp_id: this.collection.lp_id, lp_updated_at: this.collection.updated_at, action: 'checkout'}).then((result)=>{
				console.log('edit request (add_content): ' + result.status)
				this.$refs.top_collection_resource_folder.search_start()

			}).catch((e)=>{
				// if this doesn't work, don't enter edit mode
				console.log('error requesting editor checkout for collection unit add_content', e)
			})
		},

		// this fn is called when the CollectionResourceFolder emits save_unit_edits
		save_unit_edits(flag) {
			console.log('CollectionUnit: save_unit_edits: ', flag)
			if (this.viewing_users_shadow_unit) {
				this.$store.dispatch('synch_shadow_unit_assets', this.shadow_unit).then(()=>{
					this.create_shadow_unit()
					// when we're done, we have to call add_content again if we were adding from search; this re-creates the placeholder item
					if (flag == 'add_items_from_search') setTimeout(x=>this.add_content())
				})

			// if we're editing a "real" collection...
			} else if (!this.is_default_collection) {
				// CollectionResourceFolder will have made the necessary updates to the resources or lessons array of the unit, so we just have to dispatch save_learning_progression

				// However, we have to handle Instance Units differently because they don't live in collection.units
				if (this.is_instance_unit) {
					const payload = {
						user_id: this.user_info.user_id,
						unit_data: JSON.stringify(this.unit.copy_for_save())
					}
					U.ajax('edit_instance_unit', payload, result => {
						U.loading_stop()
						if (result.status != 'ok') {
							console.log('Error in ajax call'); vapp.ping(); return;
						}
						this.$emit('get_instance_units')
					})
				} else {
					this.$store.dispatch('save_learning_progression', this.collection).then(()=>{
						// if flag is 'then_checkin', immediately remove the edit lock now
						if (flag == 'then_checkin') {
							this.$store.dispatch('edit_access_control_checkin', {lp_id: this.collection.lp_id})
						}
					})
				}

			// else we're editing the default collection...
			} else {
				// so dispatch save_default_collection_resources
				// we could ask the user to confirm that the resource might still exist in other places, but this is probably too confusing...
				// this.$confirm({
				// 	title: 'Please Confirm',
				// 	text: `Note that the asset may still exist in other collections. Do you still want to remove the asset from your Sandbox?`,
				// 	acceptText: `Remove item`,
				// 	acceptIcon: 'fas fa-trash-alt',
				// 	acceptColor: 'red darken-2',
				// }).then(() => {

				this.$store.dispatch('save_default_collection_resources').then(()=>{
					// when the user removes an asset from the default collection, in addition to removing the ca_mapping record tying the asset to the default collection,
					// we also update all ca_mapping records for the asset so that they are not tied to this user. This makes it so that:
					// a. this user won't see the asset in their default collection via the "back door" that shows items they've created from other collections in their default collection
					// b. but this user, and any other users, will still see the asset in the other collections

					// PW BUT: are we accounting for the case where you're in the default collection and you remove an item from the shadow collection? In this case we actually do want to delete the shadow unit ca_mapping record...
					if (typeof flag === 'object' && 'remove' in flag) {
						U.ajax('remove_user_id_from_ca_mapping', {user_id: this.user_info.user_id, asset_id: flag['remove']}, res => {
							// Update default_collection state
							for (let i = 0; i < this.my_default_collection.units.length; i++) {
								this.my_default_collection.units[i].lessons = this.my_default_collection.units[i].lessons.filter(e => e.lesson_id !== flag['remove'])
								this.my_default_collection.units[i].resources = this.my_default_collection.units[i].resources.filter(e => e.resource_id !== flag['remove'])
							}
						})
					}
					// when we're done, we have to call add_content again if we were adding from search; this re-creates the placeholder item
					if (flag == 'add_items_from_search') this.add_content()
				})
			}
		},

		get_item_type(copied_item) {
			if (!empty(copied_item.lesson_id)) {
				return 'lesson'
			} else if (copied_item.type == 'sparkl') {
				return 'sparkl'
			} else {
				return 'resource'
			}
		},

		// Adapted from copy_to_my_content_finish, but genericized to support both 'my_content' and 'assignment' workflows
		copy_to_shadow_unit_finish(copied_item) {
			const item_type = this.get_item_type(copied_item)
			let msg
			if (this.manage_assignments && this.collection.collection_type == 'course') {
				msg = {
					lesson: 'A copy of the lesson has been added to the “My Lessons & Activities” area of this Unit. It will also appear in your Content Sandbox.<br><br>You can start modifying the lesson now.',
					sparkl: 'A copy of the activity has been added to the “My Lessons & Activities” area of this Unit. It will also appear in your Content Sandbox.<br><br>You can use the activity with your students, and/or modify the activity if you wish, now.',
					// ??? would this ever happen?
					resource: 'The resource has been imported to the “My Lessons & Activities” area of this Unit. It will also appear in your Content Sandbox.'
				}[item_type]
			} else {
				msg = {
					lesson: 'A copy of the lesson has been imported for your use. It will appear in the “My Content” area of this Unit, as well as in your Content Sandbox.<br><br>You can start modifying the lesson now.',
					sparkl: 'A copy of the activity has been imported for your use. It will appear in the “My Content” area of this Unit, as well as in your Content Sandbox.<br><br>You can use the activity with your students, and/or modify the activity if you wish, now.',
					resource: 'The resource has been imported to your “My Content” area. It will also appear in your Content Sandbox.'
				}[item_type]
			}

			// push to the shadow unit
			const target_array = item_type === 'lesson' ? this.shadow_unit.lessons : this.shadow_unit.resources
			const last_viewed_resource_id = item_type === 'lesson' ? copied_item.lesson_id : copied_item.resource_id
			this.$store.commit('set', [target_array, 'PUSH', copied_item])
			this.$store.commit('set', ['last_viewed_resource_id', last_viewed_resource_id])

			// call the synch_shadow_unit_assets service to save the shadow unit mappings, then trigger to update the shadow unit
			this.$store.dispatch('synch_shadow_unit_assets', this.shadow_unit).then((result)=>{
				// if we got back any ca_mappings, this is a course collection, and we're managing assignments...
				if (this.manage_assignments && result.added_ca_mapping_records && result.added_ca_mapping_records.length > 0 && this.collection.collection_type == 'course') {
					for (let cam of result.added_ca_mapping_records) {
						// add any new activity records (not lesson records) to my activities
						if (cam.asset_type == 'resource') {
							let a = new Activity(cam)
							this.$store.commit('add_to_my_activities', a)
						}
					}
				}
				this.create_shadow_unit()
			})

			// show the message
			this.$alert({
				text: msg,
				dialogMaxWidth: 500,
			}).then(y => {
			})
		},

		create_activity_from_resource(args) {
			// go to assignments mode, then create the activity
			this.unit_mode = 'assignments'
			setTimeout(x=>this.$refs.directives.create_new_activity(args.type, args.starting_resource, args.link_description))
		},

		share_my_content() {
			
		},
		
		subscribe_to_my_content() {

		},

		open_vlc() {

		},
	}
}
</script>

<style lang="scss">
.k-collections-unit-content-wrapper {
	flex:1 1 auto;
	border-radius:0 12px 12px 0;
	background-color:#fff;
}

.k-collections-unit-content-wrapper-single-unit {
	border-radius:12px;
}

.k-collection-unit-description-outer {
	max-width:820px;
	border-radius:10px;
	margin:8px auto 12px auto;
	border:1px solid #ccc;
	background-color:#f8f8f8;

	.fr-view table td, .fr-view table th { 
		padding:4px;
	}
}

.k-collection-unit-description {
	font-size:14px;
	line-height:18px;
	padding:12px 8px 8px 8px;
}

.k-collection-unit-description-inner {
	// PW 4/18/2024: we used to set overflow to hidden, but sometimes wierd things can happen that cause the bottoms of descriptions to become unviewable; overflow:auto means at worst you'll have to scroll to see the full description
	// overflow:hidden;
	overflow:auto;
	transition:height 0.25s;
}

.k-collection-unit-wrapper-outer {
	max-width:1000px;
	min-height:150px;

	.k-collection-unit-wrapper {
		font-size:16px;
		padding-bottom:8px;
		width:100%;
		// margin:8px;
		background-color:#fff;
		// border-radius:10px;
		overflow-x:hidden;
		overflow-y:auto;

		.k-collection-unit-section-header {
			font-weight:bold;
			background-color:$v-blue-darken-2;
			border-radius:5px;
			padding:2px 8px;
			color:#fff;
			font-size:20px;
		}
	}
}


.k-collection-unit-resources {
	.k-resource-link {
		background-color:$v-amber-accent-2;		// #fbe4d4
		border-radius:8px;
		padding:6px 2px 6px 2px;
		position:static;
		min-height:0;
		white-space: normal;
		height:100%;
		text-align:center;

		// flex:1 0 300px;

		.k-resource-link-inner {
			display:flex;
			align-items: center;
			min-height:30px;
			position:relative;
		}

		a {
			color:#000!important;	// #b48a6b
			display:flex;
			align-items: flex-start;
			// padding-top:6px;
		}
		a span:hover {
			text-decoration:underline;
		}
		.k-resource-description-text {
			flex:1 1 auto;
		}
		.v-icon.k-resource-type-icon { color:#000!important; margin-right:4px; }
		.k-resource-link-options-menu-btn {
			// margin-top:-4px;
			color:#000!important;
		}

		.k-resource-link-description {
			flex:1 1 100%;
			margin-left:5px;
			margin-top:0;
			word-break: break-word;
			line-height:16px;
			text-align:left;
		}
	}

	.k-resource-link-small {
		padding: 6px 4px 2px 3px;
		border-radius:6px;
		.k-resource-link-inner {
			min-height:10px;
		}
	}

	.k-resource-link--assessment {
		background-color:$v-pink-accent-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--teacher-facing {
		background-color:$v-blue-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--adv {
		background-color:$v-purple-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--stem {
		background-color:$v-lime-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--leader-resource {
		background-color:$v-brown-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--ese {
		background-color:$v-teal-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--not-district-sanctioned {
		background-color:$v-red-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--unit-planning-guide {
		background-color:$v-green-darken-4;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link--course-guidance {
		background-color:$v-cyan-darken-3;
		a, .v-icon.k-resource-type-icon, .k-resource-link-options-menu-btn { color:#fff!important; }
	}

	.k-resource-link-check-box {
		color:$v-pink-accent-1!important;
	}

	// .k-resource-link--search_match {
	// 	background-color:$v-yellow-lighten-3!important;
	// 	border:2px solid $v-yellow-darken-1!important;
	// 	padding:2px 6px 2px 4px;
	// }
}

.k-instance-unit-autocomplete {
	.v-select__selections { flex-wrap:nowrap!important; white-space:nowrap;}
}
</style>
