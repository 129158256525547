<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template><div :class="single_item?'k-directive-view-single-item':'k-directive-view2-outer'">
	<div v-if="!single_item" class="k-unit-view-header pb-3">
		<div v-html="header_text"></div>
		<v-spacer/>

		<v-btn v-if="n_old_items>0" small color="red darken-4" text class="k-tight-btn mr-2" @click="show_older_items=!show_older_items"><v-icon small class="mr-2">fas fa-calendar-days</v-icon>{{show_older_items?'Hide Older Items':'Show Older Items ('+n_old_items+')'}}</v-btn>

		<!-- <v-menu left><template v-slot:activator="{on}"><v-btn v-if="max_context>0" v-on="on" class="k-tight-btn" small color="#fff" @click=""><v-icon small class="mr-1">fas fa-eye</v-icon>{{contexts[context_showing]}}</v-btn></template>
			<v-list dense v-if="max_context>0">
				<v-list-item v-for="(mitem, index) in contexts" v-if="index<=max_context" :key="mitem" @click="context_showing=index"><v-list-item-title><v-icon x-small style="margin-top:-3px" color="#333" class="mr-2" v-visible="index==context_showing">fas fa-circle-check</v-icon>{{mitem}}</v-list-item-title></v-list-item>
			</v-list>
		</v-menu> -->

		<v-btn-toggle v-if="$vuetify.breakpoint.smAndUp&&items.length>0" dense active-class="k-toggle-btn-active-class" color="primary" class="k-toggle-btn" v-model="items_mode" mandatory>
			<v-btn small :value="'list'" @click.stop=""><v-icon small :color="items_mode=='list'?'#fff':'#000'">fas fa-list</v-icon></v-btn>
			<v-btn small :value="'gantt'" @click.stop="" class="k-toggle-btn-active-class-off"><v-icon small :color="items_mode=='gantt'?'#fff':'#000'">fas fa-calendar-week</v-icon></v-btn>
		</v-btn-toggle>


		<v-btn v-show="show_create_placeholder_button" small class="k-tight-btn ml-3" color="primary" @click="create_placeholder"><v-icon class="mr-1" small>fas fa-plus</v-icon>Create</v-btn>
		<v-menu left offset-y>
			<template v-slot:activator="{on}">
				<v-btn v-show="show_create_button" v-on="on" small class="k-tight-btn ml-3" color="primary" :disabled="!viewing_my_activities"><v-icon class="mr-1" small>fas fa-plus</v-icon>Create</v-btn>
			</template>
			<v-list dense>
				<v-list-item @click="create_new_lesson()"><v-list-item-icon><v-icon small>{{directive_type_icon('lesson')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{directive_type_label('lesson')}}</v-list-item-title></v-list-item>
				<v-list-item @click="create_new_activity('activity')"><v-list-item-icon><v-icon small>{{directive_type_icon('activity')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}Student Activity</v-list-item-title></v-list-item>
				<v-list-item @click="create_new_activity('google_assignment')"><v-list-item-icon><img class="k-google-assignment-icon" style="height:24px" src="https://www.gstatic.com/prof/logo_assignments_128dp.png"></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}Google Assignment</v-list-item-title></v-list-item>
				<!-- <v-list-item @click="create_new_activity('discussion')"><v-list-item-icon><v-icon small style="margin-left:3px;">{{directive_type_icon('discussion')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{directive_type_label('discussion')}}</v-list-item-title></v-list-item> -->
				<!-- <v-list-item @click="create_new_activity('quiz')"><v-list-item-icon><v-icon small style="margin-left:3px;">{{directive_type_icon('quiz')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{directive_type_label('quiz')}}</v-list-item-title></v-list-item> -->
				<!-- <v-list-item @click=""><v-list-item-icon><v-icon small style="margin-left:3px;">{{directive_type_icon('assessment')}}</v-icon></v-list-item-icon><v-list-item-title>{{max_context>1?'New ':''}}{{directive_type_label('assessment')}}</v-list-item-title></v-list-item> -->
				<v-list-item @click="import_shared_item('hc')"><v-list-item-icon><v-icon small color="purple">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import Lesson Plan or Activity from HenryConnects</v-list-item-title></v-list-item>
				<v-list-item @click="import_shared_item('inspire')"><v-list-item-icon><v-icon small color="#D4405F">fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Import Lesson Plan or Activity from GaDOE Inspire</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<div v-if="!single_item&&items.length>0" class="k-directive-view-filters">
		<v-menu nudge-left="-36" nudge-top="12">
			<template v-slot:activator="{on}">
				<v-btn v-show="context_showing>0&&items_mode=='list'" v-on="on" x-small fab :color="checkboxes_showing?'primary':'#999'" dark class="mr-2" :class="checkboxes_showing?'':'elevation-0'" style="margin-left:2px"><v-icon>far fa-square</v-icon></v-btn>
			</template>
			<v-list dense>
				<v-list-item v-if="!checkboxes_showing"><v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click.stop="checkboxes_showing=true"><v-icon small class="mr-2">far fa-square</v-icon>Show checkboxes for batch operations</v-btn></v-list-item>

				<v-list-item v-if="checkboxes_showing">
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click.stop="$refs.my_content_list.select_all"><v-icon small class="mr-2">fas fa-square-check</v-icon>Select All</v-btn>
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn" @click="$refs.my_content_list.select_none"><v-icon small class="mr-2">far fa-square</v-icon>Deselect All</v-btn>
					<v-btn small text color="primary" class="k-tight-btn k-nocaps-btn float-right" @click="checkboxes_showing=false"><v-icon small class="mr-2">fas fa-xmark</v-icon>Hide Checkboxes</v-btn>
				</v-list-item>
				<v-divider v-if="checkboxes_showing" />

				<v-list-item v-if="can_archive" @click="$refs.my_content_list.archive_selected"><v-list-item-icon><v-icon small style="margin-left:2px;">fas fa-box-archive</v-icon></v-list-item-icon><v-list-item-title><b>Archive</b> selected activities and lessons</v-list-item-title></v-list-item>
				<v-list-item v-if="checkboxes_showing" @click="$refs.my_content_list.unassign_selected(my_content_assigned_to_filter)"><v-list-item-icon><v-icon small>fas fa-users-slash</v-icon></v-list-item-icon><v-list-item-title><b>Unassign</b> selected activies for <span v-html="sections_showing"></span></v-list-item-title></v-list-item>
				<v-list-item v-if="checkboxes_showing" @click="$refs.my_content_list.copy_lesson_ids_for_selected"><v-list-item-icon><v-icon small>fas fa-share-nodes</v-icon></v-list-item-icon><v-list-item-title>Copy <b>share codes</b> for selected lessons</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<v-text-field v-model="search_terms" class="k-collection-search-input" prepend-inner-icon="fa fa-search" clearable flat light rounded clear-icon="fa fa-times-circle" label="Search…" single-line hide-details outlined solo dense background-color="#fff" :style="search_terms?'flex:0 1 400px':'flex:0 1 132px'"></v-text-field>
		<v-spacer/>
		<div v-if="is_my_sis_course&&!studentish_role" class="mr-2">Show activities for:</div>
		<v-menu bottom left max-height="60vh">
			<template v-slot:activator="{on}"><v-btn v-if="is_my_sis_course&&!studentish_role" v-on="on" small color="secondary" class="k-tight-btn k-nocaps-btn" @click=""><v-icon small class="mr-1">fas fa-eye</v-icon>
				<span v-html="sections_showing"></span>
			</v-btn></template>
			<v-list dense>
				<v-list-item @click="my_content_assigned_to_filter={}"><v-list-item-icon><v-icon v-visible="my_content_assigned_to_filter.empty()" small>fas fa-eye</v-icon></v-list-item-icon><v-list-item-title>All Sections/Students</v-list-item-title></v-list-item>
				<v-divider v-if="assigned_to_options.length>0"/>
				<v-list-item v-for="(assignee, index) in assigned_to_options" :key="assignee.class_sourcedId+assignee.user_sourcedId" @click="my_content_assigned_to_filter=assignee"><v-list-item-icon><v-icon v-visible="my_content_assigned_to_filter.matches(assignee)" small>fas fa-eye</v-icon></v-list-item-icon><v-list-item-title v-html="assignee.toString(null,true)"></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<!-- OG lessons -->
	<div v-if="new_lesson&&!(show_lesson_variant_switcher||use_enhanced_lesson_editor)" class="mb-4 k-directive-new-lesson-component">
		<div class="k-directive-item-outer k-directive-item-outer-edited elevation-2">
			<div class="k-directive-item-title">
				<v-icon class="mr-2" color="primary">{{directive_type_icon('lesson')}}</v-icon>
				<div class="k-lesson-title"><b style="font-weight:900">New {{directive_type_label('lesson')}}</b></div>
				<v-spacer/>
			</div>
			<div class="k-assignment-edit-outer pt-3">
				<LessonEditor :original_lesson="'new'" :course_code="course_code" :lp_unit_id="lp_unit_id" lesson_class="teacher" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
			</div>
		</div>
	</div>

	<!-- LPC lessons -->
	<v-dialog v-if="new_lesson&&(show_lesson_variant_switcher||use_enhanced_lesson_editor)" v-model="new_lesson" :max-width="use_enhanced_lesson_editor?1200:900" persistent scrollable :retain-focus="false" content-class="k-resource-collection-item-lesson-card-dialog k-lpe-editor-dialog">
		<v-card class="k-resource-collection-item-lesson-card">
			<div class="d-flex">
				<v-icon class="mr-2">{{directive_type_icon('lesson')}}</v-icon>
				<div class="k-lesson-title" style="font-weight:900">New {{directive_type_label('lesson')}}</div>
				<v-spacer/>
				
				<div v-if="!show_lesson_variant_switcher&&use_enhanced_lesson_editor" style="cursor:pointer; font-size:16px" @click="show_llm_lesson_plan_description"><v-icon color="light-blue" class="mr-2">fas fa-info-circle</v-icon>Lesson Plan Companion<v-icon class="k-lpe-dog-icon mx-2" style="margin-top:-5px">fas fa-dog</v-icon>(<b class="red--text text--darken-3">BETA</b>)</div>
				<v-checkbox v-if="show_lesson_variant_switcher" class="mt-0 pt-0" v-model="use_llm_for_new_lessons" @click="use_llm_clicked" hide-details off-icon="far fa-square" on-icon="fas fa-check-square"><template v-slot:label><span style="color:#444">Use Lesson Plan Companion <v-icon class="k-lpe-dog-icon" style="margin-top:-5px">fas fa-dog</v-icon> (<b class="red--text text--darken-3">BETA</b>)</span></template></v-checkbox>

			</div>
			<div :class="(use_enhanced_lesson_editor)?'k-lpe-lesson-edit-outer':'k-lesson-edit-outer'">
				<LessonEditorEnhanced v-if="use_enhanced_lesson_editor" ref="lesson_editor" :original_lesson="new_lesson" :lesson_class="'teacher'" :course_code="course_code" :lp_unit_id="lp_unit_id" :enable_lesson_shift="false" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
				<LessonEditor v-else ref="lesson_editor" :original_lesson="new_lesson" :lesson_class="'teacher'" :course_code="course_code" :lp_unit_id="lp_unit_id" @edit_lesson_cancel="create_lesson_cancel" @edit_lesson_saved="create_lesson_saved" />
			</div>
		</v-card>
	</v-dialog>

	<div v-if="new_activity" class="mb-4 k-directive-new-lesson-component">
		<div class="k-directive-item-outer k-directive-item-outer-edited elevation-2">
			<div class="k-directive-item-title">
				<v-icon v-if="new_activity.activity_type!='google_assignment'" class="mr-2" color="primary">{{directive_type_icon(new_activity.activity_type)}}</v-icon>
				<img v-if="new_activity.activity_type=='google_assignment'" class="mr-2 k-google-assignment-icon" src="https://www.gstatic.com/prof/logo_assignments_128dp.png">
				<div class="k-lesson-title"><b style="font-weight:900">New {{directive_type_label(new_activity.activity_type)}}</b></div>
				<v-spacer />
			</div>
			<div class="k-assignment-edit-outer pt-3">
				<DirectiveActivityEditor ref="activity_editor" :original_activity="new_activity" :course_code="course_code"
					:lp_unit_id="lp_unit_id" activity_class="teacher" @edit_activity_cancel="new_activity_closed"
					@edit_activity_saved="new_activity_saved" />
			</div>
		</div>
	</div>


	<div v-if="items.length==0&&!new_lesson&&!new_activity" class="k-directive-no-items elevation-2" v-html="no_items_msg"></div>

	<DirectivesList ref="my_content_list" v-if="items.length>0&&items_mode=='list'" @duplicate_item="duplicate_item" :items="items" :max_context="max_context" :show_older_items="show_older_items" :items_rendered="items_rendered" :course_code="course_code" :lp_unit_id="lp_unit_id" :n_no_date_items="n_no_date_items" :n_current_items="n_current_items" :filtered_origin_teacher_id="filtered_origin_teacher_id" :checkboxes_showing="checkboxes_showing&&items_mode=='list'" @edit_item_saved="edit_item_saved" @archive_items="archive_items" />
	<DirectivesGantt v-if="items.length>0&&items_mode=='gantt'" @duplicate_item="duplicate_item" :items="items" :max_context="max_context" :show_older_items="show_older_items" :items_rendered="items_rendered" :course_code="course_code" :lp_unit_id="lp_unit_id" :n_no_date_items="n_no_date_items" :n_current_items="n_current_items" :assigned_to_options="assigned_to_options" :filtered_origin_teacher_id="filtered_origin_teacher_id" @edit_item_saved="edit_item_saved" @archive_items="archive_items" />

	<div v-if="more_items_available" class="text-center my-4">Showing {{items_revealed}} items <v-btn small class="ml-2" color="primary" @click="items_revealed+=items_revealed_block_size">Show More</v-btn><v-btn small class="ml-2" color="primary" @click="items_revealed=-1">Show All</v-btn></div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonEditor from '../lessons/LessonEditor'
import LessonEditorEnhanced from '../lessons/LessonEditorEnhanced'
import DirectiveActivityEditor from '../directives/DirectiveActivityEditor'
import DirectivesGantt from './DirectivesGantt'
import DirectivesList from './DirectivesList'

export default {
	components: { LessonEditor, LessonEditorEnhanced, DirectiveActivityEditor, DirectivesGantt, DirectivesList },
	props: {
		// course_code: { type: String, required: false, default() { return '' } },
		// lp_unit_id: { type: Number, required: false, default() { return 0 } },
		lp: { required: false, default() { return null } },
		unit: { required: false, default() { return null } },
		single_activity_id: { type: String, required: false, default() { return '0' } },
		single_lesson_id: { type: String, required: false, default() { return '0' } },
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
		new_lesson: null,
		new_lesson_is_saved: false,
		new_activity: null,
		new_activity_is_saved: false,
		context_showing: 0,
		contexts: ['All Courses', 'This Course', 'This Unit'],
		items_rendered: false,
		n_old_items: 0,
		n_current_items: 0,
		n_no_date_items: 0,

		items_revealed_block_size: 250,
		items_revealed: 250,
		more_items_available: false,

		checkboxes_showing: false,

		search_terms: '',
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_resources', 'my_activities', 'my_ca_mappings', 'single_item', 'site_config']),
		...mapGetters(['role', 'system_role', 'studentish_role', 'activities_for_student']),

		// determine whether or not to allow the user to toggle between the LPC and the "OG" lesson editor
		show_lesson_variant_switcher() {
			return U.show_lesson_variant_switcher(0, this.lp.course_code)
		},
		
		// now separately, determine whether or not to *use* the LPC or the OG lesson editor
		use_enhanced_lesson_editor() { return this.new_lesson.lp_variant=='B' },

		// and determine which version to use for new lessons
		use_llm_for_new_lessons: {
			get() { return U.use_llm_for_new_lessons(this.lp.course_code) },
			set(val) { this.$store.commit('lst_set', ['use_llm_for_new_lessons', val]) }
		},

		single_activity_mode() { return this.single_activity_id != '0' },
		single_lesson_mode() { return this.single_lesson_id != '0' },
		is_staff() { return this.role=='staff' || this.role=='admin' },
		// lp() { return this.$store.state.all_courses.find(x=>x.course_code==this.course_code) },
		course_code() { return this.lp?.course_code || '' },
		lp_is_empty() {
			// if we don't know what lp this is coming from, assume the lp is *not* empty (??)
			if (empty(this.lp)) return false

			// return true if the course doesn't have any units or terms
			return ((!this.lp.terms || this.lp.terms.length == 0) && (!this.lp.units || this.lp.units.length == 0))
		},
		showing_course_assignments() { 
			// when viewing a unit, we're always showing unit activities & lessons ('content')
			if (this.context_showing == 2) return false
			// when viewing an lp that is 'empty' we're always showing course_assignments (non-unit-specific things -- because we don't have any units!)
			if (this.lp_is_empty) return true
			// else we're showing course_assignments if collection_assignments_or_messages_mode is explicitly set to 'course_assignments'
			return this.$store.state.lst.collection_assignments_or_messages_mode == 'course_assignments'
		},
		show_create_placeholder_button() {
			if (this.is_staff) {
				// if (this.max_context < 2 && !this.lp_is_empty) return true
				if (!this.show_create_button) return true
			}
			return false
		},
		show_create_button() {
			// staff can create...
			if (this.is_staff) {
				// if the lp is "empty" (meaning no units are defined)
				if (this.lp_is_empty) return true
				// or if we're viewing a unit
				if (this.max_context == 2) return true
				// or if we're viewing a course and we're in 'course-wide materials' mode
				if (this.max_context == 1 && this.showing_course_assignments) return true
			}
			return false
		},
		// unit() {
		// 	if (!this.lp) return null
		// 	return this.lp.units.find(x=>x.lp_unit_id == this.lp_unit_id)
		// },
		lp_unit_id() { 
			// we should receive a "shadow unit" here
			return this.unit?.shadows_lp_unit_id || 0 
		},
		viewing_my_activities() { return this.filtered_origin_teacher_id == null || this.filtered_origin_teacher_id == this.user_info.user_id },
		is_my_sis_course() {
			// return whether or not this is a course that is in my sis_courses (for any term)
			// also return false if we're not in any course
			if (this.max_context == 0) return false
			return this.$store.getters.my_sis_course_collections_all.find(x=>x.course_code == this.course_code)
		},
		items_mode: {	// list/gantt
			get() { 
				if (this.single_item) return 'list'
				return this.$store.state.lst.my_content_items_mode 
			},
			set(val) { this.$store.commit('lst_set', ['my_content_items_mode', val]) }
		},
		show_older_items: {
			get() { return this.$store.state.lst.show_older_items },
			set(val) { this.$store.commit('lst_set', ['show_older_items', val]) }
		},
		max_context() {
			// if we receive a unit id, we can go down to the unit level; if we receive a course_code we can go to that level
			if (this.lp_unit_id != 0) return 2
			if (this.course_code) return 1
			return 0
		},
		no_items_msg() {
			if (this.single_item) {
				let item_type = window.location.toString().replace(/.*((activity)|(lesson)).*/, '$1')
				if (item_type == window.location.toString()) item_type = 'item'
				item_type = U.capitalize_word(item_type)
				return `<div class="white pa-6 text-center" style="border-radius:20px">You do not have access to this ${item_type} at this time (or the ${item_type} has been removed).</div>`

			} else if (this.is_staff) {
				if (this.context_showing == 2) return 'You have not created lessons or activities for this Unit.' // Click the <i class="fas fa-eye"></i> button above to view lessons and activities for other units in this course or from across the whole site.'
				if (this.context_showing == 1) {
					if (this.showing_course_assignments) {
						return 'You have not created any course-wide materials for this Course.'
					} else {
						return 'You have not created any unit lessons or activities for this Course.'
					}
				}
				return 'You have not created any lessons or activities. Open a course to create one!'
			} else if (this.role == 'parent') {
				if (this.context_showing == 2) return `Your student does not have any assigned activities in ${this.site_config.app_name} for this Unit.`
				if (this.context_showing == 1) return `Your student does not have any assigned activities in ${this.site_config.app_name} for this Course.`
				return `Your student does not have any assigned activities in ${this.site_config.app_name}.`
			} else {
				if (this.context_showing == 2) return `You do not have any assigned activities in ${this.site_config.app_name} for this Unit.`
				if (this.context_showing == 1) return `You do not have any assigned activities in ${this.site_config.app_name} for this Course.`
				return `You do not have any assigned activities in ${this.site_config.app_name}.`
			}
		},
		header_text() {
			// return 'Assignment Center'
			if (this.max_context == 0) return 'Assignment Center'
			if (this.showing_course_assignments) {
				return 'Course-Wide Materials'
			} else {
				if (this.max_context == 1) {
					if (this.role == 'parent') return 'Unit Activities'	// My Child’s 
					else if (this.role == 'student') return 'Unit Activities'
					else return 'My Lessons & Activities: All Units'
				} else {
					if (this.role == 'parent') return 'Activities'
					else if (this.role == 'student') return 'Activities'
					else return 'My Lessons & Activities'
				}
			}
		},
		items() {
			let n_old = 0
			let n_no_date = 0
			let n_current = 0
			let arr = []

			let search_terms = this.search_terms?.toLowerCase()

			let lp_id = this.lp?.lp_id
			if (!lp_id || lp_id == 0) lp_id = Learning_Progression.collection_id_for_empty_lp(this.course_code)

			// start with this.more_items_available false; we'll set to true below if we have to skip some
			this.more_items_available = false

			//////////////////////////////////////
			// start with lessons, which should only be shown for teachers (but we have to account for a teacher viewing as a student)
			let add_lesson = (lesson) => {
				let item = {
					type: 'lesson',
					o: lesson,
					title: lesson.lesson_title,
					id: 'L' + lesson.lesson_id,
					id_for_sharing: 'L' + lesson.lesson_id,
					icon: lesson.icon(),
					has_date: !empty(lesson.lesson_date),
					is_old: lesson.item_is_old(),
					sort_date: lesson.sort_date(),
				}
				arr.push(item)
				if (item.is_old) ++n_old
				if (!item.has_date) ++n_no_date
			}

			let lesson_pool = this.my_lessons
			if (!this.studentish_role) {
				// for teachers, only show activities created by them
				lesson_pool = lesson_pool.filter(x=>x.creator_user_id == (this.filtered_origin_teacher_id ?? this.user_info.user_id))
			}

			for (let lesson of lesson_pool) {
				// don't show any lessons if we're showing a single activity_id
				if (this.single_activity_mode) break

				// show only the designated lesson if we're showing a single lesson_id
				if (this.single_lesson_mode) {
					if (lesson.lesson_id == this.single_lesson_id*1) {
						add_lesson(lesson)
						break
					}
					// if this isn't the lesson we're supposed to be showing, continue
					continue
				}

				let cam = this.my_ca_mappings.find(x=>x.asset_id == lesson.lesson_id)
				if (!cam) {
					// this will happen when a new item is first saved, but the synch_shadow_unit_assets fn hasn't yet run
					console.warn('cam record not found for lesson (might not be a bug) ', object_copy(lesson))
					continue
				}

				// if we're showing a course or unit, skip lessons that don't map to this course
				if (this.context_showing >= 1) {
					if (cam.collection_id != lp_id) continue
				}
				// if we're showing a unit, skip lessons that don't map to this unit
				if (this.context_showing == 2) {
					if (cam.unit_id != this.lp_unit_id) continue
				}

				// if we're showing the "course-wide materials", only show items where lp_unit_id is 0
				if (this.showing_course_assignments) {
					if (cam.unit_id != 0) continue
				// else don't show items where lp_unit_id is 0
				} else if (cam.unit_id == 0) continue

				// if we have search_terms, filter based on lesson title
				if (search_terms && !lesson.lesson_title.toLowerCase().includes(search_terms)) continue

				// if we make it to here add the lesson
				add_lesson(lesson)
			}

			//////////////////////////////////////
			// now activities
			let add_activity = (activity) => {
				// for sharing purposes, we want to use the tool_activity_id (for Sparkl, this is the sparkl activity_id)
				let activity_id_for_sharing = activity.tool_activity_id
				if (empty(activity_id_for_sharing)) {
					activity_id_for_sharing = 'xx' + activity.activity_id
					console.error(`Couldn’t get correct activity_id_for_sharing; using ${activity_id_for_sharing}`, activity)
				}

				let item = {
					type: 'activity',
					o: activity,
					title: activity.activity_title,
					id: 'A' + activity.activity_id,
					id_for_sharing: 'A' + activity_id_for_sharing,
					icon: activity.icon(),
					has_date: !empty(activity.available_date()) || !empty(activity.due_date()),
					is_old: activity.item_is_old(),
					sort_date: activity.sort_date(),
				}
				arr.push(item)
				if (item.is_old) ++n_old
				else if (!item.has_date) ++n_no_date
				else ++n_current
			}

			// for a student/parent, we should only have activities assigned and currently available to the student in my_activities, but account for a teacher viewing as a student
			// If parent, we should only have activities assigned the student in activities_for_student
			let activity_pool = (this.context_showing > 0)
				? this.$store.state.my_activities_by_course[lp_id]
				: (this.role === 'parent') ? this.activities_for_student : this.my_activities
			if (activity_pool && !this.studentish_role) {
				// for teachers, only show activities created by them
				activity_pool = activity_pool.filter(x=>x.creator_user_id == (this.filtered_origin_teacher_id ?? this.user_info.user_id))
			}
			
			// note: if activity_pool is null at this point, it means there aren't any my_activities for this course.
			if (activity_pool) for (let i = 0; i < activity_pool.length; ++i) {
				// don't show any activities if we're showing a single lesson_id
				if (this.single_lesson_mode) break

				let activity = activity_pool[i]

				// ??? if we're creating a new activity and have already saved it, skip the new activity, whose editor is still open
				if (this.new_activity && this.new_activity_is_saved && activity.activity_id == this.new_activity_is_saved) continue

				// show only the designated activity if we're showing a single activity_id
				if (this.single_activity_mode) {
					if (activity.activity_id == this.single_activity_id*1) {
						add_activity(activity)
						break
					}
					// if this isn't the activity we're supposed to be showing, continue
					continue
				}

				// if student or parent role, only show currently-available activities
				if (this.role == 'student' || this.role == 'parent') {
					if (!activity.available_to_students()) continue

					// if this activity's course isn't one the student is taking now or earlier in the year, don't show it
					if (this.$store.getters.my_sis_course_collections_for_directives.findIndex(x=>x.course_code == activity.course_code) == -1) {
						continue
					}

					// TODO: check assigned_to...
				}

				// if we're showing the "course-wide materials", only show items where lp_unit_id is 0
				if (this.showing_course_assignments) {
					if (activity.lp_unit_id != 0) continue
				// else don't show items where lp_unit_id is 0
				} else if (activity.lp_unit_id == 0) continue

				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && activity.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && activity.lp_unit_id != this.lp_unit_id) continue

				// if my_content_assigned_to_filter is set (and this is a staff member), limit to activities with assignees that match it
				if (!this.studentish_role && !this.my_content_assigned_to_filter.empty()) {
					// if a student is chosen, show assignments for just the student, or the student's class
					if (this.my_content_assigned_to_filter.user_sourcedId) {
						let match = false
						for (let a of activity.assigned_to) {
							if (a.matches(this.my_content_assigned_to_filter) || a.matches_class(this.my_content_assigned_to_filter)) { match = true; break; }
						}
						if (!match) continue

					// else if a section is chosen, assignees must match exactly 
					} else {
						if (!activity.includes_assignee(this.my_content_assigned_to_filter)) continue
					}
				}
				
				// if we have search_terms, filter based on activity title
				if (search_terms && !activity.activity_title.toLowerCase().includes(search_terms)) continue

				add_activity(activity)

				// if we reach items_revealed items, only show that many; user can choose to see more if they wish
				if (this.items_revealed > -1 && arr.length > this.items_revealed) {
					this.more_items_available = true
					break
				}
			}

			if (this.single_item && arr[0]) {
				// always show a the single item
				this.$store.commit('set', [arr[0].o, 'item_showing', true])
			}

			// sort by...
			arr.sort((a,b)=>{
				// see Activity.sort_date and Lesson.sort_date -- items without dates will go to the bottom
				if (a.sort_date < b.sort_date) return -1
				if (a.sort_date > b.sort_date) return 1

				// sort others by created date -- more recent first
				return b.o.created_at - a.o.created_at
			})

			// if we have more than items_revealed items, only show that many; user can choose to see more if they wish
			if (this.more_items_available) {
				arr.splice(this.items_revealed, 10000000)
			}

			// have to do this to get the outer_content_item_class method to work properly
			this.$nextTick(()=>this.items_rendered = true)

			// if (activity_pool) console.log(`CALCULATING MY ITEMS for ${this.context_showing}: ${this.course_code}/${this.lp_unit_id} - ${this.my_lessons.length}/${activity_pool.length} -- ${arr.length}`)

			// set n_old_items and return arr
			this.n_old_items = n_old
			this.n_no_date_items = n_no_date
			this.n_current_items = n_current
			return arr
		},
		past_due_items() {

		},
		my_content_assigned_to_filter: {
			get() {
				// make sure we use an Assignee object here
				if (this.max_context == 0) return new Assignee()
				let a = new Assignee(this.$store.state.lst.my_content_assigned_to_filter[this.course_code]) 
				// make sure this assignee is one of my options; if, for example, you were viewing the site earlier and had chosen a section; and now that section no longer exists; then this would cause a problem
				if (!this.assigned_to_options.find(x=>x.matches(a))) return new Assignee()
				return a
			},
			set(val) { 
				if (this.max_context == 0) return

				// make sure val is *not* an object when stored to 
				let o = extobj(this.$store.state.lst.my_content_assigned_to_filter)
				o[this.course_code] = new Assignee(val).copy_for_save()
				this.$store.commit('lst_set', ['my_content_assigned_to_filter', o]) 
			}
		},
		sections_showing() {
			if (this.my_content_assigned_to_filter.empty()) return 'All Sections/Students'
			else return this.my_content_assigned_to_filter.toString(null,true)
		},
		assigned_to_options() {
			let arr = []

			let activity_pool = (this.context_showing > 0) ? this.$store.state.my_activities_by_course[this.lp.lp_id] : this.my_activities
			if (empty(activity_pool)) return []

			for (let i = 0; i < activity_pool.length; ++i) {
				let activity = activity_pool[i]
				// limit by course_code / lp_unit_id if we get them
				if (this.context_showing == 1 && this.course_code && activity.course_code != this.course_code) continue
				if (this.context_showing == 2 && this.lp_unit_id && activity.lp_unit_id != this.lp_unit_id) continue

				// add new assignees to our arr
				if (activity.assigned_to.length > 0) {
					for (let assignee of activity.assigned_to) {
						if (!arr.find(x=>(x.class_sourcedId == assignee.class_sourcedId && x.user_sourcedId == assignee.user_sourcedId))) {
							// by using the Assignee class, we can use toString
							arr.push(new Assignee({class_sourcedId:assignee.class_sourcedId, user_sourcedId:assignee.user_sourcedId}))
						}
					}
				}
			}

			// sort with sections first
			arr.sort((a,b)=>{
				// classes come before students (it's a student if it has a user_sourcedId
				if (a.user_sourcedId && !b.user_sourcedId) return 1
				if (b.user_sourcedId && !a.user_sourcedId) return -1

				// either both are students or both are classes; either way, sort by toString
				return (a.toString() < b.toString()) ? -1 : 1
			})

			return arr
		},
		can_archive() {
			// SF: 8-14-24 : Archiving is currently only allowed from within a Unit while viewing your own Activities
			return this.checkboxes_showing && this.viewing_my_activities && this.lp_unit_id !== 0
		}
	},
	watch: {
		'$vuetify.breakpoint'() { if (!this.$vuetify.breakpoint.smAndUp) this.items_mode = 'list' }
	},
	created() {
		vapp.my_content_view = this

		// set initial context value to max_content
		this.context_showing = this.max_context
		this.check_for_items_to_move()

		// this component gets loaded directly by router if we're showing a single activity or lesson...
		if (this.single_activity_id*1 > 0 || this.single_lesson_id*1 > 0) {
			// store the single_item 'mode', which might be 'admin' or 'studentpreview'
			this.$store.commit('set', ['single_item_mode', window.location.search.substr(1)])
		
		} else {
			// get lesson_masters if we haven't already gotten done so
			this.$store.dispatch('get_lesson_masters')
		}
	},
	mounted() {
		let payload = {
			course_code: this.course_code,
		}
		this.$store.dispatch("get_messages", payload)
	},
	methods: {
		directive_type_icon(activity_type) { return U.directive_type_icon(activity_type) },
		directive_type_label(activity_type) { return U.directive_type_label(activity_type) },

		create_placeholder() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$alert('To create a lesson or activity, start by navigating to the Learning Progression and Unit the lesson or activity should be associated with, or navigate to the “Course-Wide Materials” area.')
		},

		edit_item_saved(args) {
			// note that the original version of this fn is in CollectionResourceFolder
			// args will include `type` and `updated_resource` or `updated_lesson`, and possibly `edited_lesson`
			// console.log('edit_item_saved in DirectivesWrapper!', args)

			if (args.type == 'resource' || args.type == 'activity') {
				// console.log('edit_item_saved in CollectionResourceFolder for resource')

				// sparkl activities are saved as resources
				let updated_resource = new Resource(args.updated_resource)

				// the resource will have been saved to the db; splice the updated resource to unit.resources
				// TODO: do this if it's in the shadow unit??
				let index = this.unit.resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
				if (index != -1) {
					this.unit.resources.splice(index, 1, updated_resource)
					// note that the activity will already have been saved in the unit, so we don't need to call this.save_unit_edits() here. 
					// but emit edit_resource_saved to make sure the new resource data gets transferred to the original unit if we're in CollectionUnitEdit
					this.edit_resource_saved(updated_resource)
				}

				// and/or the item might be in my_resources; try to update there
				index = this.my_resources.findIndex(x=>x.resource_id == updated_resource.resource_id)
				if (index != -1) {
					this.$store.commit('set', [this.my_resources, 'SPLICE', index, updated_resource])
				}

				// TODO??: update shadow unit and default collection...

			} else if (args.type == 'lesson') {
				let updated_lesson = args.updated_lesson

				// apply *_showing values from edited_lesson
				if (args.edited_lesson) {	// this should always be true
					updated_lesson.resources_showing = args.edited_lesson.resources_showing
					updated_lesson.standards_showing = args.edited_lesson.standards_showing
					updated_lesson.student_description_showing = args.edited_lesson.student_description_showing
					for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
						updated_lesson.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
					}
				}

				// update the item in my_lessons; the my_lessons watcher in CollectionUnit will then update the shadow unit, and the updated lesson will appear in the directives list
				let index = this.my_lessons.findIndex(x=>x.lesson_id == updated_lesson.lesson_id)
				if (index != -1) {
					this.$store.commit('set', [this.my_lessons, 'SPLICE', index, new Lesson(updated_lesson)])
				}
			}
		},

		create_new_lesson() {
			console.warn('create_new_lesson')
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			// note that we don't check out new lessons
			// this.new_lesson = 'new'

			this.new_lesson = new Lesson({
				lesson_title: '', 
				lp_variant: this.use_llm_for_new_lessons ? 'B' : 'A',
				creator_user_id: this.user_info.user_id,
			})

			this.new_lesson_is_saved = false

			// if we're using the LPC and this is the first time the user has created a lesson in this session, show the LPC info dialog
			if (this.use_llm_for_new_lessons && !vapp.lpc_info_shown) {
				vapp.show_llm_lesson_plan_description()
			}
		},

		create_lesson_saved(args) {
			// the lesson editor will have saved the resource to the db.
			// check to see if we've already added it to the shadow unit
			let state_lesson = this.items.find(x=>x.o.lesson_id == args.updated_lesson.lesson_id)?.o

			// if not already added, ...
			if (!state_lesson) {
				// apply *_showing values from edited_lesson
				if (args.edited_lesson) {	// this should always be true
					args.updated_lesson.resources_showing = args.edited_lesson.resources_showing
					args.updated_lesson.standards_showing = args.edited_lesson.standards_showing
					args.updated_lesson.student_description_showing = args.edited_lesson.student_description_showing
					for (let i = 0; i < args.edited_lesson.lesson_plan.length; ++i) {
						args.updated_lesson.lesson_plan[i].lc_showing = args.edited_lesson.lesson_plan[i].lc_showing
					}
				}

				// then add the item: since this is a brand-new item, we just add the item to the shadow_unit's lessons, then call synch_shadow_unit_assets, which will create the ca_mapping record
				let shadow_unit = this.get_shadow_unit_for_synch()
				let created_lesson = new Lesson(args.updated_lesson)
				shadow_unit.lessons.push(created_lesson)
				this.$store.dispatch('synch_shadow_unit_assets', shadow_unit).then(()=>{
					// that fn in the store will push the lesson onto my_lessons, which will cause the shadow_unit to update (in CollectionUnit) and/or cause this.items to update, and we'll see the lesson appear
				})

				// and set new_lesson to the newly-created lesson so the user can keep editing if they choose
				this.new_lesson = created_lesson

			// else we already added the new lesson, and the user kept working on it; in this case, call edit_item_saved
			} else {
				args.type = 'lesson'
				this.edit_item_saved(args)
			}

			// close the new-lesson editor, if the flag tells us to. if we don't get this flag, the user can keep editing here
			if (args.flag == 'and_close') this.new_lesson = null
		},

		create_lesson_cancel() {
			this.new_lesson = null
		},

		create_new_activity(type, starting_resource, link_description) {
			console.warn('create_new_activity')
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			// create a stub of the new activity to be created
			let o = {
				activity_type: type,
			}

			if (type == 'google_assignment') {
				o.tool_id = 'google'
			} else {
				// default: sparkl
				o.tool_id = 'sparkl'
				o.tool_activity_id = '0'
			}
			
			if (starting_resource) {
				// if we got a starting_resource, set the activity title to the link_description and set initial_content to be used for the first Sparkl exercise
				o.activity_title = link_description
				o.initial_content = (sr('<p>$1: <a class="k-host-link-nobr" title="$1" data-resource-link-id="$2" onclick="vapp.open_resource_link(\'$3\',this)"><i class="fas $4 mr-2"></i>LINK</a></p><p></p>', link_description, U.new_uuid(), starting_resource.resource_id, starting_resource.icon()))

				// after a few ticks, jump to the editor and open the activity
				setTimeout(()=>{
					this.$vuetify.goTo($(this.$el).find('.k-directive-new-lesson-component')[0], { offset: 96 })
					// TODO: open the sparkl activity, or maybe just create/save the activity automatically...
				}, 300)
			}

			// setting new_activity to o will show the editor and start the process of letting the teacher create the activity
			this.new_activity = o
			this.new_activity_is_saved = false
		},

		add_sparkl_activity_from_sparkl_activity_id(tool_activity_id) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			let o = {
				activity_type: 'activity',
				tool_activity_id: tool_activity_id,
			}

			// setting new_activity to o will show the editor and start the process of letting the teacher create the activity
			this.new_activity = o
			this.new_activity_is_saved = false
		},

		new_activity_saved(new_activity) {
			// this is only called when the user clicks 'save' or 'save and close' for a new activity
			// the activity editor will have saved the activity to the db, and new_activity will already be an Activity object
			// note that for "Course-Wide Materials", the activity editor will have saved the unit_id as 0

			// add the new activity to my_activities if necessary
			this.$store.commit('add_to_my_activities', new_activity)
			
			// we *don't* have to add to this.unit.resources, because this.unit.resources (the "shadow unit" resources) will automatically be updated by a watcher in CollectionUnit

			// set new_activity_is_saved to the new activity's activity_id, so we don't show the activity twice in the list (see above)
			this.new_activity_is_saved = new_activity.activity_id

			// if the user is closing the activity, that DirectiveActivityEditor will emit edit_activity_cancel right after this, which will reset new_activity
		},

		new_activity_closed() {
			this.new_activity = null
			this.new_activity_is_saved = false
		},

		// when we save or archive activities or lessons, we have to call synch_shadow_unit_assets to keep collection_asset_mapping records in synch
		// however, there are situations where we don't have an actual unit: the "Course-Wide Materials" area for a course, or in a course that doesn't actually have an LP.
		// for these cases, we can create a "fake shadow unit" that will work for the synch_shadow_unit_assets dispatch method in the store
		get_shadow_unit_for_synch() {
			let shadow_unit = this.unit
			if (!shadow_unit) {
				let resources = [], lessons = []
				for (let item of this.items) {
					if (item.type == 'lesson') lessons.push(item.o)
					else resources.push(item.o.resource)
				}

				let lp_id = this.lp?.lp_id
				if (!lp_id || lp_id == 0) lp_id = Learning_Progression.collection_id_for_empty_lp(this.course_code)

				shadow_unit = {
					shadows_lp_id: lp_id,
					shadows_lp_unit_id: 0,
					resources: resources,
					lessons: lessons,
				}
			}
			return shadow_unit
		},

		archive_items(items) {
			console.warn('archive_items', items)

			let shadow_unit = this.get_shadow_unit_for_synch()

			for (let item of items) {
				// to archive each item, we just remove it from the shadow_unit and call synch_shadow_unit_assets
				if (item.activity_type == 'lesson') {
					let i = shadow_unit.lessons.findIndex(x => x.lesson_id == item.lesson_id)
					if (i > -1) {
						shadow_unit.lessons.splice(i, 1)
					}
				} else {
					// if not a lesson, try to remove from unit.resources...
					let i = shadow_unit.resources.findIndex(x => x.resource_id == item.resource_id)
					if (i > -1) {
						shadow_unit.resources.splice(i, 1)
					}
					
					// then also call remove_from_my_activities if the item is an activity
					if (item.activity_type == 'activity') {
						this.$store.commit('remove_from_my_activities', item)
					}
				}
			}

			this.$store.dispatch('synch_shadow_unit_assets', shadow_unit).then(()=>{
				// this store action will remove the item from my_lessons or my_resources, which will cause the shadow_unit to update (in CollectionUnit), and we'll see the item disappear
			})
		},

		import_shared_item(from = 'hc') {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			let text, title, from_external_cureum
			if (from == 'hc') {
				from_external_cureum = 'no'
				title = 'Import Shared Lesson Plan or Activity <nobr>from HenryConnects</nobr>'
				text = 'Enter the shared lesson ID or activity ID from HenryConnects:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L” or “A”.</div>'
				// <div style="font-size:14px;font-style:italic"><b>For items shared from Inspire,</b> prefix the ID with an <span style="font-family:monospace">I</span> (e.g. “<span style="font-family:monospace">IL2302</span>”).</div>
			} else {
				from_external_cureum = 'yes'
				title = 'Import Lesson Plan or Activity from Inspire'
				text = 'Enter the shared lesson ID or activity ID from Inspire:<div class="mt-2" style="font-size:14px;font-style:italic">This ID should start with an “L” or “A”.</div>'
			}
			this.$prompt({
				title: title,
				text: text,
				dialogMaxWidth: 540,
				acceptText: 'Import',
			}).then(entered_item_id => {
				entered_item_id = $.trim(entered_item_id).toUpperCase()
				if (entered_item_id.search(/^([IALS]+)(\d+)$/) == -1) {
					this.$alert('The ID you entered is not valid.').then(()=>this.import_shared_item())
					return
				}
				let item_id_prefix = RegExp.$1
				let item_id = RegExp.$2

				// back-door functionality: "S203" adds sparkl activity 203 as a new activity
				// when importing from inspire, we may get an 'S' ID
				// TODO: not tested in hc-cureum
				if (item_id_prefix == 'S' && from != 'inspire') {
					this.add_sparkl_activity_from_sparkl_activity_id(item_id)
					return
				}

				this.import_item(from_external_cureum, item_id_prefix, item_id)
			}).catch(n=>{console.log(n)}).finally(f=>{})

		},

		import_item(from_external_cureum, item_id_prefix, item_id, new_title) {
			// follow the pattern from ResourceSearch - CollectionResourceFolder - CollectionUnit...
			// start by using copy_assets_for_adding_to_collection to copy the specified item
			let payload = {
				user_id: this.user_info.user_id, 
				from_external_cureum: from_external_cureum,
				resource_ids: [], 
				lesson_ids: [],
				agency_sanctioned: 'no',
			}

			// if we're using a sparkl_origin_override for this collection, send it in
			if (this.home_collection && !empty(this.home_collection.sparkl_origin_override)) {
				payload.sparkl_origin_override = this.home_collection.sparkl_origin_override
			}

			// if we received a new_title, send it in
			if (!empty(new_title)) payload.new_title = new_title

			if (item_id_prefix == 'L') payload.lesson_ids.push(item_id)
			else payload.resource_ids.push(item_id)

			U.loading_start()
			// TODO: don't support importing google assignments
			U.ajax('copy_assets_for_adding_to_collection', payload, result=>{
				U.loading_stop()

				if (result.status != 'ok') {
					// the service could fail because the user enters an invalid item ID
					if (result.status != 'bad_item_id') result.status = `The Item ID you entered (${item_id_prefix}${item_id}) was invalid.`
					this.$alert(result.status)
					return
				}

				if (item_id_prefix == 'L') {
					// for a lesson, add as in create_lesson_saved above
					let shadow_unit = this.get_shadow_unit_for_synch()
					shadow_unit.lessons.push(new Lesson(result.copies[0].lesson_data))
					this.$store.dispatch('synch_shadow_unit_assets', shadow_unit).then(()=>{})

				} else {
					// for an activity, the service will have created a new resource and a new copy of the sparkl activity; here we make a new activity to go with the resource and save it
					let r = new Resource(result.copies[0].resource_data)

					// add the resource to my_resources (e.g. so that when we re-create the edited_activity, the resource will be there to be added to edited_activity)
					this.$store.commit('set', [this.my_resources, 'PUSH', r])
					
					let a = new Activity({
						activity_type: 'activity',
						tool_id: 'sparkl',
						tool_activity_id: r.url,
						activity_title: r.description,
						activity_description: r.long_description,
						resource_id: r.resource_id,
						collection_id: this.lp.lp_id,
						course_code: this.course_code,
						lp_unit_id: this.lp_unit_id,
						creator_user_id: this.user_info.user_id,
					})

					let payload = {
						activity_class: 'teacher',
						activity_data: a.copy_for_save(),
						assignees: [],
					}
					this.$store.dispatch('save_activity', payload).then((result)=>{
						// recreate the activity from returned data
						let new_activity = new Activity(result.activity)

						// add the new activity to my_activities
						this.$store.commit('add_to_my_activities', new_activity)

						// add the resource to the shadow unit if we're viewing a unit
						if (this.unit) {
							this.unit.resources.push(new_activity.resource)
						}
					})
				}
			})
		},

		duplicate_item(item) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }

			console.log('duplicate_item', item)
			let noun, title, item_id_prefix, item_id
			if (item.type == 'lesson') {
				noun = 'Lesson'
				title = item.o.lesson_title
				item_id_prefix = 'L'
				item_id = item.o.lesson_id
			} else {
				noun = 'Activity'
				title = item.o.activity_title
				item_id_prefix = 'A'
				item_id = item.o.tool_activity_id
			}
			this.$prompt({
				title: 'Duplicate ' + noun,
				text: `Enter a title for the duplicated ${noun}:`,
				initialValue: title,
				disableForEmptyValue: true,
				acceptText: 'Duplicate',
				acceptIcon: 'fas fa-copy',
			}).then(title => {
				title = $.trim(title)
				this.import_item('no', item_id_prefix, item_id, title)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		check_for_items_to_move(flag) {
			// we only do this check if we're viewing a course, and if the user is not studentish
			if (this.context_showing != 1) return
			if (this.studentish_role) return

			// also we don't have to move anything for courses that don't have actual lp's (e.g. "Study Skills")
			if (this.lp.lp_id == 0) return

			// and if we're not viewing the current academic year (last available academic year), don't migrate (we don't want to migrate back!), unless flag is 'force_backwards_migration'
			// vapp.my_content_view.check_for_items_to_move('force_backwards_migration')
			if (this.user_info.academic_year != this.$store.state.available_academic_years[this.$store.state.available_academic_years.length-1]) {
				console.warn('not default year')
				if (flag != 'force_backwards_migration') return
			}

			let items_with_bad_lp_unit_ids = [], lesson_count = 0, activity_count = 0

			for (let lesson of this.my_lessons) {
				if (lesson.course_code != this.course_code) continue
				let cam = this.$store.state.my_ca_mappings.find(x=>x.asset_id==lesson.lesson_id)
				if (!cam) {
					console.warn('couldn’t find cam for lesson: ', object_copy(lesson))
					continue
				}
				if ((cam.collection_id != this.lp.lp_id) || (lesson.lp_unit_id && this.lp.units.findIndex((x)=>x.lp_unit_id==lesson.lp_unit_id) == -1)) {
					items_with_bad_lp_unit_ids.push({type:'lesson', item_id:lesson.lesson_id, lp_unit_id: lesson.lp_unit_id, ca_mapping_id:cam.ca_mapping_id})
					++lesson_count
				}
			}

			for (let activity of this.my_activities) {
				// note that for activities, the activity *is* the ca_mapping record
				if (activity.course_code != this.course_code) continue
				if ((activity.collection_id != this.lp.lp_id) || (activity.lp_unit_id && this.lp.units.findIndex((x)=>x.lp_unit_id==activity.lp_unit_id) == -1)) {
					// the activity_id is the ca_mapping_id
					items_with_bad_lp_unit_ids.push({type:'activity', item_id:activity.activity_id, lp_unit_id: activity.lp_unit_id, ca_mapping_id:activity.activity_id})
					++activity_count
				}
			}
			// console.warn('check_for_items_to_move', this.lp.lp_id, items_with_bad_lp_unit_ids)

			if (items_with_bad_lp_unit_ids.length > 0) {
				// When we move from one academic year to another, the lp_unit_ids for each course change. When we detect this, offer to the user to correct the lp_unit_ids
				let count_string = ''
				if (lesson_count > 0) count_string = lesson_count + U.ps(' lesson', lesson_count)
				if (activity_count > 0) {
					if (count_string) count_string += ' and '
					count_string += activity_count + U.ps(' activity', activity_count, ' activities')
				}
				let s = `One or more of your lessons and activities (${count_string}) appear to have been created in a previous iteration of this course (i.e. in a prior academic year). Click MIGRATE ITEMS below to move these items into their corresponding Units of the current iteration of the course. (Note: if any units have been renamed in the current version of the course, lessons and activities from those units will be placed in Unit 1 of the current course.)`
				this.$confirm({
					title: 'Migrate Lessons and Activities',
					text: s,
					acceptText: 'Migrate Items Now',
					cancelText: 'Don’t Migrate at this time',
					dialogMaxWidth: 620,
				}).then(y => {
					let payload = {
						user_id: this.user_info.user_id,
						course_code: this.course_code,
						items_with_bad_lp_unit_ids: items_with_bad_lp_unit_ids,
					}
					U.loading_start()
					U.ajax('migrate_items_with_bad_lp_unit_ids', payload, result=>{
						U.loading_stop()
						if (result.status != 'ok') {
							this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
						}

						this.$alert({
							text: 'Reload to see the lessons and activities.',
							acceptText: 'Reload',
							focusBtn: true,		// focus on the accept btn when dialog is rendered
						}).then(y => {
							window.location.reload()
						}).catch(n=>{console.log(n)}).finally(f=>{})

					});
				}).catch(n=>{console.log(n)}).finally(f=>{})
			}
		},

		use_llm_clicked(confirmed) {
			// if we're here, the user has clicked to switch between the OG and LLC editor. warn them if they've already started editing
			if (!empty(this.$refs.lesson_editor.edited_lesson.lesson_title) && confirmed !== true) {
				this.$confirm({
					text: 'If you switch the lesson editor interface at this time, you will lose any work you’ve already done on this lesson in the current editor interface. Are you sure you want to switch?',
					acceptText: 'Yes, Switch',
				}).then(y => {
					this.use_llm_clicked(true)
					
				}).catch(n=>{
					// if they switch to cancel, revert use_llm_for_new_lessons and don't switch
					this.use_llm_for_new_lessons = !this.use_llm_for_new_lessons

				}).finally(f=>{})
				return
			}

			// copy through the title if entered
			this.new_lesson.lesson_title = this.$refs.lesson_editor.edited_lesson.lesson_title

			if (this.use_llm_for_new_lessons) {
				this.new_lesson.lp_variant = 'B'
				this.show_llm_lesson_plan_description()
			} else {
				this.new_lesson.lp_variant = 'A'
			}
		},
		show_llm_lesson_plan_description() { vapp.show_llm_lesson_plan_description() },
	}
}
</script>

<style lang="scss">
.k-directive-view2-outer {
	// width:800px;
	// max-width:860px;
	max-width:100vw;
	margin:0 auto;
	text-align:left;
	padding-top:12px;
}

.k-directive-view2-outer-expanded {
	position: fixed;
	width:1000px;
	left: calc(50vw - 500px);
	// width: calc(100vw - 40px);
	// left: 20px;
	// height: 640px;
	// top: calc(50vh - 320px);
	// height: calc(100vh - 40px);
	// top: 20px;
	top:70px;
	max-height:calc(100vh - 90px);
	overflow:auto;
	z-index: 10000000;
}

.k-directive-view2-outer-scrim {
	position:fixed;
	width:100vw;
	height:100vh;
	background-color:rgba(0,0,0,0.8);
	z-index: 9999999;
	display:none;
}

.k-directive-view2-outer-expanded .k-directive-view2-outer-scrim {
	display:block;
}

.k-unit-view-header {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
	font-size:24px;
	line-height:30px;
	font-weight:bold;
	margin-bottom:8px;
	// margin-left:8px;
	display:flex;
	align-items:center;
}

.k-directive-view-filters {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
	text-align:left;
	font-size:14px;
	// line-height:30px;
	// font-weight:bold;
	margin-bottom:8px;
	// margin-left:8px;
	display:flex;
	align-items:center;
}

.k-my-content-view-list-outer {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-mc-gantt-item-showing-outer {
	width:860px;
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-directive-new-lesson-component {
	max-width:860px;
	margin-left:auto;
	margin-right:auto;
}

.k-directive-item-outer {
	background-color:#f5f5f5;
	border-radius:8px;
	padding:6px;
	margin-top:0;
	margin-bottom:4px;
}

.k-directive-item-outer-hovered {
	background-color:#fff;
}

.k-directive-item-outer-showing {
	background-color:#fff;
	margin-top:12px;
	margin-bottom:12px;
}

.k-directive-item-outer-edited {
	background-color:#fff;
	border:2px solid #666;
	position:relative;	// needed for positioning of close/save btns
	z-index: 1;	// makes close/save btns and froala appear behind spinner
}

.k-directive-item-view-mid {
	border-radius:8px;
	padding:2px;
}

.k-directive-item-title {
	display:flex;
	align-items: center;
}

.k-directive-no-items {
	max-width:880px;
	margin-left:auto;
	margin-right:auto;
	background-color:#fff;
	border-radius:8px;
	margin-top:12px;
	padding:12px;
	font-size:16px;
	font-style: italic;
}

.k-directive-view-single-item {
	margin-top:60px;
}

// note that this needs to be last, so that the background color overwrites others above
.k-directive-item-outer-last-viewed {
	background-color:$v-amber-lighten-5;
}
.k-directive-item-outer-last-viewed-hovered {
	background-color:$v-amber-lighten-4;
}
</style>
