<!-- Copyright 2024, Common Good Learning Tools LLC -->
<template><v-dialog v-model="dialog_open" max-width="1200" overlay-opacity="0.9" scrollable @click:outside="$emit('dialog_cancel')" style="border-radius:16px">
<v-card class="k-home-dialog-card">
	<div style="position:absolute; left:10px; top:8px; width:44px; height:44px; text-align:center; padding-top:6px;background-color:#000; border-radius:36px;"><v-icon style="color:#fff;">{{ home_icon }}</v-icon></div>
	<div style="position:absolute; right:12px; top:12px;">
		<!-- <v-btn v-if="!signed_in&&site_config.show_banner_signin_btn=='yes'" :color="site_config.banner_signin_btn_color" class="mr-2" @click="sign_in">  Sign In<v-icon small class="mx-2">fas fa-sign-in</v-icon></v-btn> -->
		<v-btn color="primary" class="xk-tight-btn" @click="$emit('dialog_cancel')">Let’s Get Started! <v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
	</div>

	<!-- home_header_html can be set in config file -->
	<div v-if="site_config.home_header_html" v-html="unsanitize_html(site_config.home_header_html)"></div>

	<div class="k-home-dialog-wrapper-inner">
		<div class="fr-view" v-html="unsanitize_html(home_page_content)"></div>

		<div style="clear:both; border-top:1px solid #ccc; margin-top:0px;">
			<div v-if="oidc_info" class="mt-3 pt-2 mb-1" style="font-size:16px;" v-html="oidc_info"></div>
			<div v-if="!signed_in" class="text-center mt-4 mb-2"><v-btn color="secondary" @click="sign_in">Sign In to {{ site_config.app_name }}<v-icon small class="ml-2">fas fa-sign-in</v-icon></v-btn></div>
		</div>

		<!-- site_footer_html can be set in config file -->
		<div v-if="site_config.site_footer_html" v-html="unsanitize_html(site_config.site_footer_html)"></div>
	</div>

</v-card>
</v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
// components: { HomeDialog },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		// add to includer component
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters(['signed_in', 'role']),
		home_icon() {
			return this.site_config.customized_tabs?.home?.icon || 'fas fa-home'
		},
		home_page_variant() {
			// if we only have one variant, return it
			if (this.site_config.home_page_variants.length == 1) {
				return this.site_config.home_page_variants[0]
			}

			// if not signed in and we have an unsignedin variant, return it
			if (!this.signed_in && this.site_config.home_page_variants.includes('unsignedin')) return 'unsignedin'

			// else try to return varient matching role, but if we don't have a student or parent varient, just return 'staff', which will serve for 'signedin'
			if (this.role == 'student' && this.site_config.home_page_variants.includes('student')) return 'student'
			if (this.role == 'parent' && this.site_config.home_page_variants.includes('parent')) return 'parent'	// in this case you should be viewing FamilyHome...
			return 'staff'
		},
		home_page_content() {
			this.$store.dispatch('get_home_page_content', this.home_page_variant)
			return this.$store.state.home_page_content[this.home_page_variant]
		},
		oidc_info() {
			let s = ''
			if (this.user_info.first_name) s += sr('<div>You are signed in as $1 $2 ($3)</div>', this.user_info.first_name, this.user_info.last_name, this.user_info.email)
			
			if (this.site_config.show_user_system_data_on_home == 'yes') {
				s += '<ul class="ml-4">'
				if (this.user_info.oidc_data?.system_name) s += sr('<li>School system: $1</li>', this.user_info.oidc_data.system_name)
				if (this.user_info.oidc_data?.school_name) s += sr('<li>School: $1</li>', this.user_info.oidc_data.school_name)
				if (this.user_info.oidc_data?.user_role) s += sr('<li>Role: $1</li>', this.user_info.oidc_data.user_role)
				if (this.user_info.first_name) s += '</ul>'
			}
			return s
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		unsanitize_html(html) {
			if (empty(html)) return ''
			html = html.replace(/%3A/g, ':')
			html = html.replace(/%24/g, '$')
			return html
		},

		sign_in() {
			this.$emit('dialog_cancel')
			vapp.redirect_to_login()
		},
	}
}
</script>

<style lang="scss">
.k-home-dialog-card {
	// padding:12px;
	border-radius:16px!important;
	width:100%;
	overflow:auto;
}

.k-home-dialog-wrapper-inner {
	background-color:#fff;
	padding:12px;
	border-radius:0 0 16px 16px;
	font-size:18px;
	line-height:1.4em;
	p, h1, h2, h3, ul, ol {
		max-width:800px;
	}

	.fr-view {
		font-size:18px;
		line-height:1.4em;

		h2 {
			font-size:27px;
			line-height:33px;
		}
		h3 {
			font-size:22px;
			line-height:27px;
		}
	}

}
</style>
