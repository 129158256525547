// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		section_text(component) {
			if (empty(component.lc_content)) return ''
	
			// render latex
			let content = U.render_latex(component.lc_content)

			// make sure all a tags open in a new window (an a tag might get two target tags, but that's OK)
			content = content.replace(/<a /g, '<a target="_blank" ')

			let mdc = this.master_component(component)
			if (empty(mdc)) return content

			// regardless of lc_text_format, if the content includes a block tag, just return the plain content
			if (content.search(/<(p|ul|li|div|table)\b/) > -1) {
				return content
			}

			if (empty(mdc?.lc_text_format) || mdc.lc_text_format == 'paragraphs') {
				// for 'paragraphs' style, or if we don't have a master component or lc_text_format is empty, just return the plain content
				return content

			} else if (mdc.lc_text_format == 'line') {
				// for single-line style (e.g. questions), wrap in a paragraph
				return `<p>${content}</p>`

			} else {
				// else assume it's a list (could be, e.g. 'list' or 'list_plain'; use unordered list
				let lines = content.split('<br>')
				let s = `<ul>`
				for (let line of lines) s += `<li>${line}</li>`
				s += `</ul>`
				return s
			}
		},

	}
}